import React from "react";
import { PageRoute } from "@quest-finance/quest-fe-shared/dist/common/types/PageRoute";

const ResimacApplicationList = React.lazy(
  () => import("../pages/ResimacApplicationList")
);

const routes: PageRoute[] = [
  {
    name: "Resimac Application List",
    path: "/funder/resimac",
    exact: true,
    component: ResimacApplicationList,
  },
];

export default routes;
