import React from "react";
import { Collapse } from "antd";
import { QfCollapse } from "../../../../theme";
import CreditRefList from "../../../components/credit-reference/List";

const { Panel } = Collapse;
const RefStatements: React.FunctionComponent = () => {
  return (
    <QfCollapse
      defaultActiveKey="applicant-guarantor"
      expandIconPosition="right"
      ghost
    >
      <Panel
        header={<div className="section-header-1">Credit Reference</div>}
        key="applicant-guarantor"
      >
        <CreditRefList />
      </Panel>
    </QfCollapse>
  );
};

export default RefStatements;
