import React from "react";
import AuditLogList from "../../../components/audit-log-list/AuditLogList";

const AuditLogs: React.FunctionComponent = () => {
  return (
    <div className="content-section">
      <AuditLogList />
    </div>
  );
};

export default AuditLogs;
