import { applicationStatuses } from "@quest-finance/quest-fe-shared/dist/application";
import {
  WorkflowSummaryDetails,
  WorkflowGroupTaskCount,
  WorkflowResponse,
} from "../types/Workflow";

export enum WORKFLOW_STATE {
  PENDING = "PENDING",
  PASS = "PASS",
  REFER = "REFER",
  HOLD = "HOLD",
  DECLINED = "DECLINED",
  DISABLED = "DISABLED",
}

export const closedStatus: applicationStatuses.APPLICATION_STATUSES[] = [
  applicationStatuses.APPLICATION_STATUSES.SETTLED,
  applicationStatuses.APPLICATION_STATUSES.WITHDRAWN_BY_INTRODUCER,
  applicationStatuses.APPLICATION_STATUSES.WITHDRAWN_BY_LENDER,
  applicationStatuses.APPLICATION_STATUSES.DECLINED,
];

export const workflowResponseDefaultValue: WorkflowResponse = {
  id: 0,
  templateId: 0,
  code: "",
  assigneeId: "",
  targetId: "",
  state: "",
  isSuccess: false,
  closedAt: "",
  closedBy: "",
  closedReason: "",
  createdAt: "",
  updatedAt: "",
  workflowTemplate: {
    id: 0,
    code: "",
    text: "",
    title: "",
    description: "",
    clientId: 0,
    targetModel: "",
    versionNumber: 0,
    createdAt: "",
    updatedAt: "",
  },
  groups: {},
};

export const workflowGroupTaskCountDefaultValue: WorkflowGroupTaskCount = {
  DECLINED: 0,
  HOLD: 0,
  PASS: 0,
  PENDING: 0,
  REFER: 0,
  TOTAL: 0,
};

export enum TASK_INPUT_TYPES {
  SELECT = "SELECT",
  TEXT = "TEXT",
}

export const workflowSummaryDetailsDefaultValue: WorkflowSummaryDetails = {
  QUOTE: {
    state: "PENDING",
    isSuccess: null,
  },
  APPLICANT: {
    state: "PENDING",
    isSuccess: null,
  },
  GUARANTOR: {
    state: "PENDING",
    isSuccess: null,
  },
  SECURITY: {
    state: "PENDING",
    isSuccess: null,
  },
  SETTLEMENT: {
    state: "PENDING",
    isSuccess: null,
  },
};

export enum WORKFLOW_CODE {
  CREDIT_WORKFLOW = "CREDIT_WORKFLOW",
  SETTLEMENT_WORKFLOW = "SETTLEMENT_WORKFLOW",
}
