import APIService from "@quest-finance/quest-fe-shared/dist/common/services/APIService";
import { ListResponse } from "@quest-finance/quest-fe-shared/dist/common/types/ListResponse";
import { SingleResponse } from "@quest-finance/quest-fe-shared/dist/common/types/SingleResponse";
import * as qs from "query-string";
import { AML_KYC_STATUS } from "../constants/assessmentExtras";
import { AssessmentDetail } from "../types/AssessmentDetail";
import { RequestParams } from "../types/AssessmentList";

class CreditService {
  public static async getEquifaxReport(
    assessmentId: string,
    entityIds: number[],
    isIndepth = false
  ): Promise<SingleResponse<unknown>> {
    const result = await APIService.jsonRequest<
      SingleResponse<unknown>,
      unknown
    >(
      {
        method: "POST",
        path: `/assessment/assessments/${assessmentId}/credit-report/generate-equifax-report`,
        data: { entityIds, isIndepth },
      },
      true
    );
    return result;
  }

  public static async list(
    payload: RequestParams
  ): Promise<ListResponse<AssessmentDetail>> {
    const queryParams = qs.stringify(payload, {
      arrayFormat: "comma",
    });

    const result = await APIService.jsonRequest<
      ListResponse<AssessmentDetail>,
      unknown
    >(
      {
        method: "GET",
        path: `/assessment/assessments?${queryParams}`,
      },
      true
    );
    return result;
  }

  public static async refreshIndepthReport(
    assessmentId: string
  ): Promise<SingleResponse<unknown>> {
    const result = await APIService.jsonRequest<
      SingleResponse<unknown>,
      unknown
    >(
      {
        method: "POST",
        path: `/assessment/assessments/${assessmentId}/credit-report/reload-equifax-indepth-document`,
      },
      true
    );
    return result;
  }

  public static async getCommercialApply(
    assessmentId: string,
    entityIds: number[]
  ): Promise<SingleResponse<unknown>> {
    const result = await APIService.jsonRequest<
      SingleResponse<unknown>,
      { entityIds: number[] }
    >(
      {
        method: "POST",
        path: `/assessment/assessments/${assessmentId}/credit-report/generate-equifax-commercial-apply`,
        data: { entityIds },
      },
      true
    );
    return result;
  }

  public static async getIllionReport(
    assessmentId: string,
    entityIds: number[]
  ): Promise<SingleResponse<unknown>> {
    const result = await APIService.jsonRequest<
      SingleResponse<unknown>,
      unknown
    >(
      {
        method: "POST",
        path: `/assessment/assessments/${assessmentId}/credit-report/generate-illion-report`,
        data: { entityIds },
      },
      true
    );
    return result;
  }

  public static async getFrankieOneReport(
    assessmentId: string,
    entityIds: number[]
  ): Promise<SingleResponse<unknown>> {
    const result = await APIService.jsonRequest<
      SingleResponse<unknown>,
      unknown
    >(
      {
        method: "POST",
        path: `/assessment/assessments/${assessmentId}/kyc/generate-frankieone-report`,
        data: { entityIds },
      },
      true
    );
    return result;
  }

  public static async getFrankieOneEntityStatus(
    assessmentId: string,
    frankieOneId: string
  ): Promise<SingleResponse<{ status: AML_KYC_STATUS }>> {
    const result = await APIService.jsonRequest<
      SingleResponse<{ status: AML_KYC_STATUS }>,
      unknown
    >(
      {
        method: "GET",
        path: `/assessment/assessments/${assessmentId}/kyc/${frankieOneId}`,
      },
      true
    );
    return result;
  }
}

export default CreditService;
