import { LOADING_STATUS } from "@quest-finance/quest-fe-shared/dist/common/constants/loadingStatuses";
import * as actionTypes from "../actions/types/assessmentExtras";
import { ASSESSMENT_EXTRAS_DEFAULT_VALUE } from "../constants/assessmentExtras";
import { AssessmentExtrasState } from "../types/AssessmentExtras";

const assessmentExtrasState: AssessmentExtrasState = ASSESSMENT_EXTRAS_DEFAULT_VALUE;

export const reducer = (
  state: AssessmentExtrasState = assessmentExtrasState,
  action: actionTypes.AssessmentExtrasActions
): AssessmentExtrasState => {
  switch (action.type) {
    case actionTypes.CE_GET_ASSESSMENT_EXTRAS: {
      return {
        ...state,
        status: LOADING_STATUS.LOADING,
      };
    }
    case actionTypes.CE_GET_ASSESSMENT_EXTRAS_SUCCESS: {
      return {
        ...state,
        status: LOADING_STATUS.SUCCESS,
        assessmentExtras: {
          ...state.assessmentExtras,
          ...action.response,
        },
      };
    }
    case actionTypes.CE_GET_ASSESSMENT_EXTRAS_FAILED: {
      return {
        ...state,
        status: LOADING_STATUS.FAILED,
        error: action.error,
      };
    }
    case actionTypes.CE_RESET_ASSESSMENT_EXTRAS: {
      return {
        ...assessmentExtrasState,
      };
    }
    default: {
      return state;
    }
  }
};
