import "./StatusReasonModal.scss";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CloseCircleFilled } from "@ant-design/icons";
import { applicationStatuses } from "@quest-finance/quest-fe-shared/dist/application";
import { Button, Form, Input, message, Modal, Select, Spin } from "antd";
import { processError } from "../../../common/utils/error";
import AssessmentService from "../../services/AssessmentService";
import {
  AssessmentDetail,
  AssessmentStatusRequest,
} from "../../types/AssessmentDetail";

import { StatusReason, StatusReasonList } from "../../types/Status";

const { APPLICATION_STATUS_LABELS } = applicationStatuses;

type StatusReasonModalProps = {
  assessment: AssessmentDetail;
  visible: boolean;
  status: string;
  editMode: boolean;
  onCancel: () => void;
  onSuccess: (status: AssessmentDetail) => void;
};

const StatusReasonModal: React.FunctionComponent<StatusReasonModalProps> = ({
  assessment,
  visible,
  status,
  editMode,
  onCancel,
  onSuccess,
}: StatusReasonModalProps) => {
  const [options, setOptions] = useState<StatusReasonList[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [statusRequest, setStatusRequest] = useState<AssessmentStatusRequest>({
    status,
  });
  const [selectedChildReasonId, setSelectedChildReasonId] = useState<number>();
  const childOptions = useMemo<StatusReason[]>(() => {
    let children: StatusReason[] = [];

    if (statusRequest.reasonId) {
      const selected = options.find(
        (option) => option.id === statusRequest.reasonId
      );
      if (selected && selected.children) {
        children = selected.children;
      }
    }

    return children;
  }, [options, statusRequest.reasonId]);
  const selectedReason = useMemo(() => {
    if (statusRequest.reasonId) {
      return options.find((option) => option.id === statusRequest.reasonId);
    }
  }, [options, statusRequest]);
  const selectedChildReason = useMemo(() => {
    if (selectedChildReasonId && childOptions.length > 0) {
      return childOptions.find((option) => selectedChildReasonId === option.id);
    }
  }, [childOptions, selectedChildReasonId]);

  const getStatusReasons = useCallback(async () => {
    setIsLoading(true);
    try {
      const statusFilter = editMode ? assessment.status : status;
      const result = await AssessmentService.getStatusReasons([statusFilter]);
      const options = result.data.filter((option) => !option.parentId);
      setOptions(options);
    } catch (error) {
      processError(error, (errorMessage) => {
        message.error(errorMessage);
      });
    } finally {
      setIsLoading(false);
    }
  }, [status, editMode, assessment]);

  const handleSubmit = async () => {
    if (typeof statusRequest.reasonId === "undefined") {
      message.error("Please select a reason for the status");
      return;
    }
    if (
      (selectedReason?.allowCustomReason ||
        selectedChildReason?.allowCustomReason) &&
      !statusRequest.statusReason
    ) {
      message.error("Please fill up the reason for this status");
      return;
    }
    setSubmitLoading(true);
    try {
      const statusReason =
        selectedReason?.allowCustomReason ||
        selectedChildReason?.allowCustomReason
          ? statusRequest.statusReason
          : undefined;
      const { data } = await AssessmentService.updateAssessmentStatus(
        assessment.id,
        {
          ...statusRequest,
          reasonId: selectedChildReasonId || statusRequest.reasonId,
          statusReason,
        }
      );
      message.success("Status successfully saved");
      onSuccess(data);
    } catch (error) {
      processError(error, (errorMessage) => {
        message.error(errorMessage);
      });
    } finally {
      setSubmitLoading(false);
    }
  };

  useEffect(() => {
    if (visible) {
      getStatusReasons();
    }
  }, [visible, getStatusReasons]);

  useEffect(() => {
    let statusRequest: AssessmentStatusRequest = {
      status,
    };
    if (editMode && assessment.reason) {
      const { reason } = assessment;
      statusRequest = {
        ...statusRequest,
        status: assessment.status,
        statusReason: assessment.statusReason,
      };
      if (reason.parent) {
        statusRequest = {
          ...statusRequest,
          reasonId: assessment.reason.parentId,
        };
        setSelectedChildReasonId(assessment.reason.id);
      } else {
        statusRequest = {
          ...statusRequest,
          reasonId: assessment.reason.id,
        };
      }
    }
    setStatusRequest(statusRequest);
  }, [editMode, status, assessment]);

  const handleReasonIdChange = (value: number) => {
    setStatusRequest((prevState) => ({
      ...prevState,
      reasonId: value,
      statusReason: undefined,
    }));
    setSelectedChildReasonId(undefined);
  };

  const handleReasonChange = (value: string) => {
    setStatusRequest((prevState) => ({
      ...prevState,
      statusReason: value,
    }));
  };

  return (
    <Modal
      title={
        <span data-testid="status-modal-title">
          Change status for{" "}
          <strong>{assessment.data.applicationNumber as string}</strong> to{" "}
          <strong>{APPLICATION_STATUS_LABELS[status]}</strong>
        </span>
      }
      visible={visible}
      destroyOnClose={true}
      footer={null}
      onCancel={onCancel}
      className="status-reason-modal"
      closeIcon={<CloseCircleFilled />}
      data-testid="status-reason-modal"
    >
      <Spin spinning={isLoading}>
        <Form layout="vertical">
          <Form.Item label="Reason">
            <Select
              disabled={submitLoading}
              value={statusRequest.reasonId}
              onChange={(value) => handleReasonIdChange(value)}
              placeholder="Select a reason"
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              data-testid="status-reason-dropdown-one"
              virtual={false}
            >
              {options.map((status) => (
                <Select.Option key={status.id} value={status.id}>
                  {status.reason}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {childOptions.length > 0 && (
            <Form.Item>
              <Select
                disabled={submitLoading}
                value={selectedChildReasonId}
                onChange={(value) => setSelectedChildReasonId(value)}
                placeholder="Select a reason"
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                data-testid="status-reason-dropdown-two"
              >
                {childOptions.map((status) => (
                  <Select.Option key={status.id} value={status.id}>
                    {status.reason}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}

          {(selectedReason?.allowCustomReason ||
            selectedChildReason?.allowCustomReason) && (
            <Form.Item label="">
              <Input.TextArea
                value={statusRequest.statusReason as string}
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                  const { value } = event.target;
                  handleReasonChange(value);
                }}
                disabled={submitLoading}
                placeholder="Enter the reason"
                maxLength={500}
                autoSize={{ minRows: 1, maxRows: 6 }}
                showCount
                data-testid="status-reason-custom"
              />
            </Form.Item>
          )}
          <Button
            className="qf-btn-green status-save-btn"
            loading={submitLoading}
            onClick={handleSubmit}
            data-testid="status-reason-save-modal"
          >
            Save
          </Button>
        </Form>
      </Spin>
    </Modal>
  );
};

export default StatusReasonModal;
