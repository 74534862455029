import "./WorkflowModal.scss";
import React, { useCallback, useEffect, useState } from "react";
import { CloseCircleFilled } from "@ant-design/icons";
import {
  documentPurposes,
  applicationStatuses,
} from "@quest-finance/quest-fe-shared/dist/application";
import { LabelValue } from "@quest-finance/quest-fe-shared/dist/common/types/LabelValue";
import { useDownloadDocument } from "@quest-finance/quest-fe-shared/dist/files";
import {
  Button,
  Form,
  Input,
  Modal,
  Select,
  Spin,
  message,
  Col,
  Row,
  Divider,
} from "antd";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { assessmentStatusSelector } from "../../../assessment-v2/selectors/assessmentDetail";
import { processError } from "../../../common/utils/error";
import { Uploader } from "../../../document";
import { updateWorkflowTask } from "../../actions/creators/workflow";
import {
  TASK_INPUT_TYPES,
  WORKFLOW_STATE,
  closedStatus,
} from "../../constants/workflow";
import { useWorkflowDispatch } from "../../dispatchers";
import { useTaskUpdate } from "../../hooks/useTaskUpdate";
import WorkflowDocumentService from "../../services/WorkflowDocumentService";
import WorkflowService from "../../services/WorkflowService";
import { WorkflowDocument } from "../../types/Document";
import { UpdateTaskOutcome, WorkflowTask } from "../../types/Workflow";
import DocumentList from "../document-list/DocumentList";
import WorkflowTaskNotes from "../task-notes";

type WorkflowModalProps = {
  isVisible: boolean;
  workflowId: number;
  workflowTask: WorkflowTask;
  onCancel?: () => void;
};

const WorkflowModal: React.FunctionComponent<WorkflowModalProps> = ({
  isVisible,
  workflowId,
  workflowTask,
  onCancel,
}: WorkflowModalProps) => {
  const [documentsLoading, setDocumentsLoading] = useState(false);
  const assessmentStatus = useSelector(
    assessmentStatusSelector
  ) as applicationStatuses.APPLICATION_STATUSES;
  const getDocuments = useCallback(async () => {
    try {
      setDocumentsLoading(true);
      const { data } = await WorkflowDocumentService.getList(workflowTask.id);
      setDocuments(data);
      setDocumentsLoading(false);
    } catch (e) {
      setDocumentsLoading(false);
      processError(e, (errorMessage) => {
        message.error(errorMessage);
      });
    }
  }, [workflowTask]);
  const isWorkflowClosed = closedStatus.includes(assessmentStatus);
  const isPass = workflowTask.state === WORKFLOW_STATE.PASS;
  const isRefer = workflowTask.state === WORKFLOW_STATE.REFER;
  const isHold = workflowTask.state === WORKFLOW_STATE.HOLD;
  const isDeclined = workflowTask.state === WORKFLOW_STATE.DECLINED;

  const outcomes = workflowTask.taskTemplate.outcomes.map((outcome) => ({
    label: outcome.description as string,
    value: outcome.value as string,
  }));
  const [documents, setDocuments] = useState<WorkflowDocument[]>([]);

  const [selectedOutcome, setOutcome] = useState<LabelValue>();
  const [outcomeText, setOutcomeText] = useState<string>("");
  const [requestOutcomeLoading, setRequestOutcomeLoading] = useState(false);

  const workflowDispatch = useWorkflowDispatch();
  const { downloadFile } = useDownloadDocument();
  const { isLoading, updateTaskState } = useTaskUpdate(
    workflowId,
    workflowTask.id
  );

  const taskUpdateHandler = (newState: WORKFLOW_STATE) => {
    if (workflowTask.state !== newState && !isWorkflowClosed)
      updateTaskState(newState);
  };

  const setOutcomeRequest = useCallback(
    async (data: UpdateTaskOutcome) => {
      try {
        setRequestOutcomeLoading(true);
        const result = await WorkflowService.updateTaskOutcome(
          workflowId,
          workflowTask.id,
          data
        );
        workflowDispatch(updateWorkflowTask(workflowTask.id, result.data));
        setRequestOutcomeLoading(false);
        message.success("Successfully changed the outcome of the task");
      } catch (e) {
        processError(e, (errorMessage) => {
          message.error(errorMessage);
        });
        setRequestOutcomeLoading(false);
      }
    },
    [workflowId, workflowTask, workflowDispatch]
  );

  useEffect(() => {
    getDocuments();

    return () => {
      setDocuments([]);
    };
  }, [getDocuments]);

  const handleChangeOutcome = (value: LabelValue) => {
    setOutcome(value);
  };

  const handleSendRequest = () => {
    const data: UpdateTaskOutcome = {
      outcome: outcomeText,
      outcomeParams: {},
    };
    if (
      selectedOutcome &&
      workflowTask.taskTemplate.inputType === TASK_INPUT_TYPES.SELECT
    ) {
      const outcome = workflowTask.taskTemplate.outcomes.find(
        (outcome) => outcome.value === selectedOutcome
      );
      if (outcome) {
        data.outcome = outcome.value as string;
      }
    }

    setOutcomeRequest(data);
  };

  const handleOnUploadComplete = () => {
    getDocuments();
  };

  const handleDocumentDelete = async (documentId: string) => {
    try {
      setDocumentsLoading(true);
      await WorkflowDocumentService.deleteDocument(workflowTask.id, documentId);
      setDocumentsLoading(false);
      getDocuments();
      message.success("Successfully deleted task document");
    } catch (e) {
      processError(e, (errorMessage) => {
        message.error(errorMessage);
      });
      setDocumentsLoading(false);
    }
  };

  const handleDownload = (documentId: string) => {
    downloadFile(
      `/workflow/task/${workflowTask.id}/documents/${documentId}/download`
    );
  };

  return (
    <Modal
      className="qf-workflow-task-modal"
      visible={isVisible}
      footer={null}
      width={1024}
      onCancel={onCancel}
      closeIcon={<CloseCircleFilled />}
      destroyOnClose
    >
      <Spin spinning={requestOutcomeLoading || isLoading}>
        <Row gutter={[16, 16]}>
          <Col sm={24} md={14}>
            <h3 className="f-quest-navy f-bold mb-5">
              {workflowTask.taskTemplate.text}
            </h3>
          </Col>
          <Col sm={24} md={10}>
            {workflowTask.state === WORKFLOW_STATE.PENDING ? (
              <Form layout="inline" className="outcome-input">
                {workflowTask.taskTemplate.inputType ===
                TASK_INPUT_TYPES.SELECT ? (
                  <Form.Item
                    style={{
                      minWidth: "181px",
                    }}
                  >
                    <Select<LabelValue>
                      value={selectedOutcome}
                      onChange={handleChangeOutcome}
                      options={outcomes}
                      disabled={requestOutcomeLoading || isWorkflowClosed}
                    />
                  </Form.Item>
                ) : (
                  <Form.Item>
                    <Input
                      value={outcomeText}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setOutcomeText(event.target.value)
                      }
                      disabled={requestOutcomeLoading || isWorkflowClosed}
                    />
                  </Form.Item>
                )}
                <Form.Item>
                  <Button
                    className="qf-btn-green"
                    onClick={handleSendRequest}
                    disabled={requestOutcomeLoading || isWorkflowClosed}
                  >
                    Set Outcome
                  </Button>
                </Form.Item>
              </Form>
            ) : (
              <div className="outcome-text">
                {workflowTask.outcomeDescription}{" "}
                {workflowTask.outcomeParams
                  ? `- ${workflowTask.outcomeParams.value}`
                  : ""}
                {}
              </div>
            )}
            <div className="state-btn-cont">
              <Button
                className={classNames("state-btn", {
                  "qf-btn-gray-invert hover-fill": !isPass,
                  "qf-btn-green": isPass,
                  inactive: isWorkflowClosed,
                })}
                onClick={() => taskUpdateHandler(WORKFLOW_STATE.PASS)}
                disabled={
                  workflowTask.outcome === null || (isWorkflowClosed && !isPass)
                }
              >
                Pass
              </Button>
              <Button
                className={classNames("state-btn", {
                  "qf-btn-gray-invert hover-fill": !isHold,
                  "qf-btn-orange": isHold,
                  inactive: isWorkflowClosed,
                })}
                disabled={
                  workflowTask.outcome === null || (isWorkflowClosed && !isHold)
                }
                onClick={() => taskUpdateHandler(WORKFLOW_STATE.HOLD)}
              >
                Hold
              </Button>
              <Button
                className={classNames("state-btn", {
                  "qf-btn-gray-invert hover-fill": !isRefer,
                  "qf-btn-yellow": isRefer,
                  inactive: isWorkflowClosed,
                })}
                disabled={
                  workflowTask.outcome === null ||
                  (isWorkflowClosed && !isRefer)
                }
                onClick={() => taskUpdateHandler(WORKFLOW_STATE.REFER)}
              >
                Refer
              </Button>
              <Button
                className={classNames("state-btn", {
                  "qf-btn-gray-invert hover-fill": !isDeclined,
                  "qf-btn-red": isDeclined,
                  inactive: isWorkflowClosed,
                })}
                disabled={
                  workflowTask.outcome === null ||
                  (isWorkflowClosed && !isDeclined)
                }
                onClick={() => taskUpdateHandler(WORKFLOW_STATE.DECLINED)}
              >
                Decline
              </Button>
            </div>
          </Col>
        </Row>
        <Divider />
        <WorkflowTaskNotes workflowTaskId={workflowTask.id} />
        <Divider />
        <Row>
          <Col span={24}>
            <h3 className="f-quest-navy f-bold mb-3">Documents</h3>
            <section className="uploader-container">
              {!isWorkflowClosed && (
                <Uploader
                  documentPurpose={
                    documentPurposes.DOCUMENT_PURPOSES.INTERNAL_DOCUMENT
                  }
                  uploadPath={`workflow/task/${workflowTask.id}/documents`}
                  className="mb-3"
                  onUploadComplete={handleOnUploadComplete}
                />
              )}
            </section>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <DocumentList
              isLoading={documentsLoading}
              documents={documents}
              onDelete={handleDocumentDelete}
              onDownload={handleDownload}
              readOnly={isWorkflowClosed}
            />
          </Col>
        </Row>
      </Spin>
    </Modal>
  );
};

export default WorkflowModal;
