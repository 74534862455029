import { useCallback, useState } from "react";
import { message } from "antd";
import { processError } from "../../common/utils/error";
import { updateWorkflowTask } from "../actions/creators/workflow";
import { WORKFLOW_STATE } from "../constants/workflow";
import { useWorkflowDispatch } from "../dispatchers";
import WorkflowService from "../services/WorkflowService";

export const useTaskUpdate = (
  workflowId: number,
  taskId: number,
  onSuccess?: () => void
): {
  isLoading: boolean;
  updateTaskState: (state: WORKFLOW_STATE) => Promise<void>;
} => {
  const [isLoading, setIsLoading] = useState(false);
  const workflowDispatch = useWorkflowDispatch();

  const updateTaskState = useCallback(
    async (state: WORKFLOW_STATE) => {
      try {
        setIsLoading(true);
        const result = await WorkflowService.updateTaskState(
          workflowId,
          taskId,
          { state }
        );
        workflowDispatch(updateWorkflowTask(taskId, result.data));
        if (onSuccess) onSuccess();

        message.success("Successfully updated task state.");
      } catch (error) {
        processError(error, (errorMessage) => {
          message.error(errorMessage);
        });
      } finally {
        setIsLoading(false);
      }
    },
    [workflowDispatch, workflowId, taskId, onSuccess]
  );

  return {
    isLoading,
    updateTaskState,
  };
};
