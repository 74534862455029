import React from "react";
import {
  TrademarkCircleOutlined,
  ProfileOutlined,
  IdcardOutlined,
  AuditOutlined,
  TeamOutlined,
  LeftSquareOutlined,
  RightSquareOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";
import { getUserDataSelector } from "@quest-finance/quest-fe-shared/dist/auth";
import { UserData } from "@quest-finance/quest-fe-shared/dist/auth/types/UserData";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { isAllowedAccess, permissions } from "../../../../iam";
import { setSidebarCollapse } from "../../../actions/creators/layout";
import { useLayoutDispatch } from "../../../dispatchers/";
import "./Sidebar.scss";

interface TwoColSidebarProps {
  collapsed?: boolean;
}

const TwoColSidebar: React.FunctionComponent<TwoColSidebarProps> = ({
  collapsed = false,
}: TwoColSidebarProps) => {
  const userData = useSelector(getUserDataSelector) as UserData;
  const layoutDispatch = useLayoutDispatch();

  const onToggleHandler = () => {
    layoutDispatch(setSidebarCollapse(!collapsed));
  };

  return (
    <div className="layout-sider">
      <div className="toggle-btn" onClick={onToggleHandler}>
        {collapsed ? <RightSquareOutlined /> : <LeftSquareOutlined />}
      </div>
      {isAllowedAccess(userData, [
        permissions.PERMISSION_IAM["ASSESSMENT.APPLICATION.READ"],
      ]) && (
        <Link to="/">
          <AuditOutlined />
          <span className="title">Applications</span>
        </Link>
      )}

      {isAllowedAccess(userData, [
        permissions.PERMISSION_IAM.IAM_ADMIN,
        permissions.PERMISSION_IAM.IAM_INTRODUCER_MANAGE,
        permissions.PERMISSION_IAM["IAM.CLIENT.CREATE"],
        permissions.PERMISSION_IAM["IAM.CLIENT.UPDATE_INTRODUCER"],
      ]) && (
        <Link to="/iam/clients">
          <TeamOutlined />
          <span className="title">Clients</span>
        </Link>
      )}
      {isAllowedAccess(userData as UserData, [
        permissions.PERMISSION_IAM.IAM_ADMIN,
        permissions.PERMISSION_IAM["IAM.ROLE.CREATE"],
      ]) && (
        <Link to="/iam/roles">
          <ProfileOutlined />
          <span className="title">Roles</span>
        </Link>
      )}
      {isAllowedAccess(userData as UserData, [
        permissions.PERMISSION_IAM.IAM_ADMIN,
        permissions.PERMISSION_IAM["IAM.USER.CREATE"],
        permissions.PERMISSION_IAM.IAM_INTRODUCER_MANAGE,
        permissions.PERMISSION_IAM["IAM.USER.CREATE_INTRODUCER"],
      ]) && (
        <Link to="/iam/users">
          <IdcardOutlined />
          <span className="title">Users</span>
        </Link>
      )}
      {isAllowedAccess(userData as UserData, [
        permissions.PERMISSION_IAM["FUNDER.UPDATE"],
      ]) && (
        <Link to="/funder/resimac">
          <TrademarkCircleOutlined />
          <span className="title">Resimac</span>
        </Link>
      )}
      {isAllowedAccess(userData as UserData, [
        permissions.PERMISSION_IAM["IAM.USER.READ"],
        permissions.PERMISSION_IAM["APPLICATION.MOVE"],
        permissions.PERMISSION_IAM["APPLICATION.READ_ALL"],
      ]) && (
        <Link to="/application/applications/move">
          <EnvironmentOutlined />
          <span className="title">Move Application</span>
        </Link>
      )}
    </div>
  );
};

export default TwoColSidebar;
