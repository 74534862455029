import "./Guarantors.scss";
import React, { useMemo, useState } from "react";
import { FormOutlined } from "@ant-design/icons";
import { getApplicationSelector } from "@quest-finance/quest-fe-shared/dist/application";
import {
  GUARANTOR_ASSET_TYPES,
  GUARANTOR_ASSET_TYPE_LABELS,
} from "@quest-finance/quest-fe-shared/dist/application/constants/guarantorAssetTypes";
import { GUARANTOR_LIABILITY_TYPES_LABELS } from "@quest-finance/quest-fe-shared/dist/application/constants/guarantorLiabilityTypes";
import { GUARANTOR_RESIDENTIAL_STATUSES_OPTIONS } from "@quest-finance/quest-fe-shared/dist/application/constants/guarantorResidentialStatuses";
import { MARITAL_STATUS_LABELS } from "@quest-finance/quest-fe-shared/dist/application/constants/maritalStatuses";
import { GuarantorAssetLiability } from "@quest-finance/quest-fe-shared/dist/application/types/Guarantor";
import { LOADING_STATUS } from "@quest-finance/quest-fe-shared/dist/common/constants/loadingStatuses";
import { TITLE_OPTIONS } from "@quest-finance/quest-fe-shared/dist/common/constants/titles";
import { dateFormat } from "@quest-finance/quest-fe-shared/dist/common/utils/date";
import { ERROR_CODES } from "@quest-finance/quest-fe-shared/dist/error-handler";
import { Col, Collapse, Divider, message, Row, Skeleton, Spin } from "antd";
import { AxiosError } from "axios";
import { differenceInYears } from "date-fns";
import NumberFormat from "react-number-format";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Guarantor, ApplicationResponse } from "../../../application";
import {
  DATE_LIST_FORMAT,
  LONG_DATE_FORMAT,
} from "../../../common/contstants/app";
import { processError } from "../../../common/utils/error";
import { QfCollapse } from "../../../theme";
import { getAssessmentExtras } from "../../actions/creators/assessmentExtras";
import {
  GUARANTOR1_SCORE_KEY,
  GUARANTOR2_SCORE_KEY,
  GUARANTOR1_ILLION_KEY,
  GUARANTOR2_ILLION_KEY,
  GUARANTOR1_FRANKIEONE_KEY,
  GUARANTOR2_FRANKIEONE_KEY,
  GUARANTOR1_EQUIFAX_APPLY,
  GUARANTOR2_EQUIFAX_APPLY,
} from "../../constants/assessmentExtras";
import { useAssessmentExtrasDispatch } from "../../dispatchers";
import {
  assessmentExtrasDataSelector,
  assessmentExtrasStatusSelector,
} from "../../selectors/assessmentExtras";
import CreditService from "../../services/CreditService";
import { ScoreResponse } from "../../types/AssessmentExtras";
import { CreditButtonMultiple } from "./credit-score/CreditButton";
import CreditScore from "./credit-score/CreditScore";

type AssetsLiabilityProps = {
  type: "assets" | "liabilities";
  assetsLiabilities: GuarantorAssetLiability[];
};

const AssetsLiability: React.FunctionComponent<AssetsLiabilityProps> = ({
  type,
  assetsLiabilities,
}: AssetsLiabilityProps) => {
  const typeLabels =
    type === "assets"
      ? GUARANTOR_ASSET_TYPE_LABELS
      : GUARANTOR_LIABILITY_TYPES_LABELS;

  let total = 0;

  if (assetsLiabilities.length > 0) {
    total = assetsLiabilities
      .map((asset) => asset.amount)
      .reduce((previousValue, currentValue) => {
        return previousValue + currentValue;
      });
  }

  return (
    <div className="assets-liabilities">
      <table>
        <thead>
          <tr>
            <th>{type === "assets" ? "Assets" : "Liabilities"}</th>
            <th className="value">Value</th>
          </tr>
        </thead>
        <tbody>
          {assetsLiabilities.map((assetLiability, index) => (
            <tr key={index}>
              <td>{typeLabels[assetLiability.type]}</td>
              <td className="value">
                <NumberFormat
                  value={assetLiability.amount}
                  prefix="$"
                  displayType="text"
                  fixedDecimalScale
                  thousandSeparator=","
                  decimalScale={2}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Divider className="sum qf-divider" />
      <table>
        <tbody>
          <tr>
            <td>Total {type === "assets" ? "Assets" : "Liabilities"}</td>
            <td className="value">
              <NumberFormat
                value={total}
                prefix="$"
                displayType="text"
                fixedDecimalScale
                thousandSeparator=","
                decimalScale={2}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const { Panel } = Collapse;

const Guarantors: React.FunctionComponent = () => {
  const { id, guarantors, assessmentId } = useSelector(
    getApplicationSelector
  ) as ApplicationResponse;
  const assessmentExtrasDispatch = useAssessmentExtrasDispatch();
  const assessmentExtrasStatus = useSelector(assessmentExtrasStatusSelector);
  const assessmentExtras = useSelector(assessmentExtrasDataSelector);
  const [illionRequestLoading, setIllionRequestLoading] = useState(false);
  const [amlRequestLoading, setAmlRequestLoading] = useState(false);
  const [equifaxApplyLoading, setEquifaxApplyLoading] = useState(false);
  const entityIds = guarantors?.map((guarantor) => guarantor.entityId);
  const guarantorIllions = useMemo<
    {
      guarantorNo: string;
      lastRequest: string;
    }[]
  >(() => {
    const guarantorIllions: {
      guarantorNo: string;
      lastRequest: string;
    }[] = [];

    guarantors.forEach((_, index) => {
      const guarantorNo = index + 1;
      const illionData = assessmentExtras
        ? (assessmentExtras[
            `ILLION.CONSUMER_CREDIT.GUARANTOR_${guarantorNo}`
          ] as ScoreResponse) ?? null
        : null;

      const lastRequest =
        illionData && illionData.updatedAt
          ? dateFormat(new Date(illionData.updatedAt), DATE_LIST_FORMAT)
          : "";

      if (lastRequest) {
        guarantorIllions.push({
          guarantorNo: guarantorNo.toString(),
          lastRequest,
        });
      }
    });

    return guarantorIllions;
  }, [assessmentExtras, guarantors]);

  const guarantorFrankieOnes = useMemo<
    {
      guarantorNo: string;
      lastRequest: string;
    }[]
  >(() => {
    const guarantorFrankieOnes: {
      guarantorNo: string;
      lastRequest: string;
    }[] = [];

    guarantors.forEach((_, index) => {
      const guarantorNo = index + 1;
      const frankieOneData = assessmentExtras
        ? (assessmentExtras[
            `FRANKIEONE.CREATE_VERIFY.GUARANTOR_${guarantorNo}`
          ] as ScoreResponse) ?? null
        : null;

      const lastRequest =
        frankieOneData && frankieOneData.updatedAt
          ? dateFormat(new Date(frankieOneData.updatedAt), DATE_LIST_FORMAT)
          : "";

      if (lastRequest) {
        guarantorFrankieOnes.push({
          guarantorNo: guarantorNo.toString(),
          lastRequest,
        });
      }
    });

    return guarantorFrankieOnes;
  }, [assessmentExtras, guarantors]);

  const guarantorEquifax = useMemo<
    {
      guarantorNo: string;
      lastRequest: string;
    }[]
  >(() => {
    const guarantorEquifax: {
      guarantorNo: string;
      lastRequest: string;
    }[] = [];

    guarantors.forEach((_, index) => {
      const guarantorNo = index + 1;
      const equifaxData = assessmentExtras
        ? (assessmentExtras[
            `EQUIFAX.COMPANY_APPLY.GUARANTOR_${guarantorNo}`
          ] as ScoreResponse) ?? null
        : null;

      const lastRequest =
        equifaxData && equifaxData.updatedAt
          ? dateFormat(new Date(equifaxData.updatedAt), DATE_LIST_FORMAT)
          : "";

      if (lastRequest) {
        guarantorEquifax.push({
          guarantorNo: guarantorNo.toString(),
          lastRequest,
        });
      }
    });

    return guarantorEquifax;
  }, [assessmentExtras, guarantors]);

  const netAssets = (guarantor: Guarantor) => {
    let totalAssets = 0;
    if (guarantor.assets.length > 0) {
      totalAssets = guarantor.assets
        .map((asset) => asset.amount)
        .reduce((previousValue, currentValue) => {
          return previousValue + currentValue;
        });
    }

    let totalLiabilities = 0;
    if (guarantor.liabilities.length > 0) {
      totalLiabilities = guarantor.liabilities
        .map((liability) => liability.amount)
        .reduce((previousValue, currentValue) => {
          return previousValue + currentValue;
        });
    }

    const netPosition = totalAssets - totalLiabilities;

    return netPosition;
  };

  const renderInvestmentPropertyAddress = (guarantor: Guarantor) => {
    const address = [
      guarantor.investmentPropertyAddressUnitNumber,
      guarantor.investmentPropertyAddressStreetNumber,
      guarantor.investmentPropertyAddressStreetName,
      guarantor.investmentPropertyAddressSuburb,
      guarantor.investmentPropertyAddressState,
      guarantor.investmentPropertyAddressPostcode,
    ]
      .filter((address) => !!address)
      .join(" ");

    if (address) {
      return (
        (guarantor.investmentPropertyAddressUnitNumber
          ? `${guarantor.investmentPropertyAddressUnitNumber}/`
          : "") +
        [
          guarantor.investmentPropertyAddressStreetNumber,
          guarantor.investmentPropertyAddressStreetName,
          guarantor.investmentPropertyAddressSuburb,
          guarantor.investmentPropertyAddressState,
          guarantor.investmentPropertyAddressPostcode,
        ]
          .filter((address) => !!address)
          .join(" ")
      );
    }

    return "-";
  };

  let content = <Skeleton active />;

  if (guarantors && guarantors.length) {
    content = (
      <QfCollapse
        defaultActiveKey={["guarantor-0", "guarantor-1"]}
        className="collapsible"
        expandIconPosition="right"
        ghost
      >
        {guarantors.map((guarantor, index) => {
          const guarantorNo = index + 1;

          return (
            <Panel
              header={
                <>{`Guarantor ${index + 1} - ${
                  TITLE_OPTIONS[guarantor.title as string]
                } ${guarantor.firstName} ${guarantor.middleName ?? ""} ${
                  guarantor.lastName
                }`}</>
              }
              key={`guarantor-${index}`}
              className="panel application-data"
            >
              <CreditScore
                assessmentId={assessmentId as string}
                guarantor={guarantor}
                guarantorNo={guarantorNo}
                amlKycAllLoading={amlRequestLoading}
                illionAllLoading={illionRequestLoading}
                equifaxAllLoading={equifaxApplyLoading}
              />
              <div className="section-header">Personal Info</div>
              <Row className="info">
                <Col span={24} md={8}>
                  <span className="label">Drivers licence</span> <br />
                  <span className="value">
                    {guarantor.driverLicenseNumber ?? "-"}{" "}
                    {guarantor.driverLicenseState
                      ? `(${guarantor.driverLicenseState})`
                      : ""}
                  </span>
                </Col>
                <Col span={24} md={16}>
                  <span className="label">Licence Card number</span> <br />
                  <span className="value">
                    {guarantor.driverLicenseCardNumber ?? "-"}
                  </span>
                </Col>
              </Row>
              <Row className="info">
                <Col md={8} sm={12}>
                  <span className="label">DOB|Age</span> <br />
                  <span className="value">
                    {dateFormat(
                      new Date(guarantor.dateOfBirth as string),
                      LONG_DATE_FORMAT
                    )}{" "}
                    (
                    {differenceInYears(
                      new Date(),
                      new Date(guarantor.dateOfBirth as string)
                    )}{" "}
                    yrs)
                  </span>
                </Col>
                <Col md={16} sm={12}>
                  <span className="label">Marital status</span> <br />
                  <span className="value">
                    {MARITAL_STATUS_LABELS[guarantor.maritalStatus as string]}{" "}
                    {guarantor.dependentNumber
                      ? `w/ ${guarantor.dependentNumber}`
                      : ""}
                  </span>
                </Col>
              </Row>
              <Row className="info">
                <Col md={8} sm={12}>
                  <span className="label">Mobile</span> <br />
                  <span className="value">{guarantor.mobile}</span>
                </Col>
                <Col md={16} sm={12}>
                  <span className="label">Email</span> <br />
                  <span className="value">{guarantor.email as string}</span>
                </Col>
              </Row>
              <Row className="info">
                <Col md={8} sm={12}>
                  <span className="label">Residential status</span> <br />
                  <span className="value">
                    {
                      GUARANTOR_RESIDENTIAL_STATUSES_OPTIONS[
                        guarantor.residentialStatus as string
                      ]
                    }
                  </span>
                </Col>
                <Col md={16} sm={12}>
                  <span className="label">Residential address</span> <br />
                  <span className="value">
                    {guarantor.addressUnitNumber
                      ? `${guarantor.addressUnitNumber}/`
                      : ""}
                    {[
                      guarantor.addressStreetNumber,
                      guarantor.addressStreetName,
                      guarantor.addressSuburb,
                      guarantor.addressState,
                      guarantor.addressPostcode,
                    ]
                      .filter((address) => !!address)
                      .join(" ")}
                  </span>
                </Col>
              </Row>
              {guarantor.assets.findIndex(
                (asset) =>
                  asset.type === GUARANTOR_ASSET_TYPES.INVESTMENT_PROPERTY
              ) !== -1 && (
                <Row className="info">
                  <Col md={8} sm={12}>
                    <span className="label">Property status</span> <br />
                    <span className="value">Invesment</span>
                  </Col>
                  <Col md={16} sm={12}>
                    <span className="label">
                      Invesment property address optional
                    </span>{" "}
                    <br />
                    <span className="value">
                      {renderInvestmentPropertyAddress(guarantor)}
                    </span>
                  </Col>
                </Row>
              )}
              <div className="section-header">Assets and Liabilities</div>
              <Row gutter={[32, 16]}>
                <Col md={12}>
                  <AssetsLiability
                    type="assets"
                    assetsLiabilities={guarantor.assets}
                  />
                </Col>
                <Col md={12}>
                  <AssetsLiability
                    type="liabilities"
                    assetsLiabilities={guarantor.liabilities}
                  />
                </Col>
              </Row>

              <Row gutter={[32, 16]}>
                <Col md={12}>
                  <Divider className="sum total" />
                  <table
                    style={{
                      width: "100%",
                      tableLayout: "fixed",
                    }}
                    className="net-assets"
                  >
                    <tbody>
                      <tr>
                        <td>Net assets</td>
                        <td style={{ textAlign: "right" }}>
                          <NumberFormat
                            value={netAssets(guarantor)}
                            prefix="$"
                            displayType="text"
                            fixedDecimalScale
                            thousandSeparator=","
                            decimalScale={2}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
              </Row>
            </Panel>
          );
        })}
      </QfCollapse>
    );
  }

  const generateIllionCCR = async () => {
    if (!assessmentId) return;
    setIllionRequestLoading(true);
    try {
      await CreditService.getIllionReport(assessmentId, entityIds);
      assessmentExtrasDispatch(
        getAssessmentExtras(assessmentId, {
          [GUARANTOR1_SCORE_KEY]: ["illion"],
          [GUARANTOR2_SCORE_KEY]: ["illion"],
          [GUARANTOR1_ILLION_KEY]: ["score"],
          [GUARANTOR2_ILLION_KEY]: ["score"],
        })
      );
      message.success(
        `Successfully retrieved score/documents for all guarantors`
      );
    } catch (error) {
      const apiError = error as AxiosError;
      if (apiError.response?.data.errorCode === ERROR_CODES.TOO_MANY_REQUESTS) {
        message.error(
          "An ongoing request is still in progress. It will be completed short while."
        );
      } else {
        processError(error, (errorMessage) => {
          message.error(errorMessage);
        });
      }
    } finally {
      setIllionRequestLoading(false);
    }
  };

  const generateAMLandKYC = async () => {
    if (!assessmentId) return;
    setAmlRequestLoading(true);
    try {
      await CreditService.getFrankieOneReport(
        assessmentId as string,
        entityIds
      );

      assessmentExtrasDispatch(
        getAssessmentExtras(assessmentId, {
          [GUARANTOR1_FRANKIEONE_KEY]: ["score", "entity_id", "status"],
          [GUARANTOR2_FRANKIEONE_KEY]: ["score", "entity_id", "status"],
        })
      );
      message.success(
        `Successfully retrieved score/documents for all guarantors`
      );
    } catch (error) {
      const apiError = error as AxiosError;
      if (apiError.response?.data.errorCode === ERROR_CODES.TOO_MANY_REQUESTS) {
        message.error(
          "An ongoing request is still in progress. It will be completed short while."
        );
      } else {
        processError(error, (errorMessage) => {
          message.error(errorMessage);
        });
      }
    } finally {
      setAmlRequestLoading(false);
    }
  };

  const generateEquifaxApply = async () => {
    if (!assessmentId) return;
    setEquifaxApplyLoading(true);
    try {
      await CreditService.getCommercialApply(assessmentId as string, entityIds);

      assessmentExtrasDispatch(
        getAssessmentExtras(assessmentId, {
          [GUARANTOR1_SCORE_KEY]: ["equifax"],
          [GUARANTOR2_SCORE_KEY]: ["equifax"],
          [GUARANTOR1_EQUIFAX_APPLY]: ["score"],
          [GUARANTOR2_EQUIFAX_APPLY]: ["score"],
        })
      );
      message.success(
        `Successfully retrieved score/documents for all guarantors`
      );
    } catch (error) {
      const apiError = error as AxiosError;
      if (apiError.response?.data.errorCode === ERROR_CODES.TOO_MANY_REQUESTS) {
        message.error(
          "An ongoing request is still in progress. It will be completed short while."
        );
      } else {
        processError(error, (errorMessage) => {
          message.error(errorMessage);
        });
      }
    } finally {
      setEquifaxApplyLoading(false);
    }
  };

  return (
    <div className="guarantors-wrapper">
      <Spin spinning={assessmentExtrasStatus === LOADING_STATUS.LOADING}>
        <div className="header">
          <div className="header-title">
            <div className="label">
              Guarantors{" "}
              <Link to={`/application/applications/${id}/guarantors`}>
                <FormOutlined className="edit-link" />
              </Link>
            </div>
          </div>
          <div className="credit-buttons-header">
            <CreditButtonMultiple
              className="equifax"
              getPopupContainer={() =>
                document.querySelector(".guarantors-wrapper") as HTMLElement
              }
              onClick={generateEquifaxApply}
              data={guarantorEquifax}
              requestLoading={equifaxApplyLoading}
              popUpPlacement="topRight"
              label="Run all Equifax Apply"
            />
            <CreditButtonMultiple
              className="illion"
              getPopupContainer={() =>
                document.querySelector(".guarantors-wrapper") as HTMLElement
              }
              onClick={generateIllionCCR}
              data={guarantorIllions}
              requestLoading={illionRequestLoading}
              popUpPlacement="topRight"
              label="Run all Illion CCR"
            />
            <CreditButtonMultiple
              className="aml-kyc"
              getPopupContainer={() =>
                document.querySelector(".guarantors-wrapper") as HTMLElement
              }
              onClick={generateAMLandKYC}
              data={guarantorFrankieOnes}
              requestLoading={amlRequestLoading}
              popUpPlacement="topRight"
              label="Run all AML/KYC"
            />
          </div>
        </div>
        {content}
      </Spin>
    </div>
  );
};

export default Guarantors;
