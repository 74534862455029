import { createSelector } from "@reduxjs/toolkit";
import { WorkflowDetailsState } from "../types/Workflow";
import { WorkflowState } from "../types/WorkflowState";

export const workflowSelector = (state: WorkflowState): WorkflowDetailsState =>
  state.workflow.workflowDetails;

export const workflowDetailsSelector = createSelector(
  workflowSelector,
  (workflowDetails) => workflowDetails.workflowDetails.data
);
export const workflowDetailsStatusSelector = createSelector(
  workflowSelector,
  (workflowDetails) => workflowDetails.workflowDetails.status
);
export const workflowDetailsErrorSelector = createSelector(
  workflowSelector,
  (workflowDetails) => workflowDetails.workflowDetails.error
);

export const workflowSummaryDetailsSelector = createSelector(
  workflowSelector,
  (workflowDetails) => workflowDetails.workflowSummaryDetails.data
);
export const workflowSummaryDetailsStatusSelector = createSelector(
  workflowSelector,
  (workflowDetails) => workflowDetails.workflowSummaryDetails.status
);
export const workflowSummaryDetailsErrorSelector = createSelector(
  workflowSelector,
  (workflowDetails) => workflowDetails.workflowSummaryDetails.error
);

export const workflowGroupTasksSelector = createSelector(
  workflowSelector,
  (workflowDetails) => workflowDetails.workflowTasks.data.tasks
);
export const workflowGroupTasksCountSelector = createSelector(
  workflowSelector,
  (workflowDetails) => workflowDetails.workflowTasks.data.count
);
export const workflowGroupTasksStatusSelector = createSelector(
  workflowSelector,
  (workflowDetails) => workflowDetails.workflowTasks.status
);
export const workflowGroupIdsSelector = createSelector(
  workflowSelector,
  (workflowDetails) => workflowDetails.workflowTasks.data.groupIds
);
export const workflowGroupTasksErrorSelector = createSelector(
  workflowSelector,
  (workflowDetails) => workflowDetails.workflowTasks.error
);
