import React from "react";
import { Collapse } from "antd";
import { useParams } from "react-router";
import { QfCollapse } from "../../../../theme";
import { WORKFLOW_CODE } from "../../../../workflow/constants/workflow";
import SecurityData from "../../../components/security-data/SecurityData";
import SupportingDocuments from "../../../components/supporting-documents/SupportingDocuments";
import WorkflowSection from "../../../components/workflow-section/WorkflowSection";
import { Slug } from "../../../types/Navigation";

const { Panel } = Collapse;

const Security: React.FunctionComponent = () => {
  const urlParams = useParams<{
    assessmentId: string;
  }>();
  return (
    <>
      <QfCollapse
        defaultActiveKey={["application-data"]}
        expandIconPosition="right"
        ghost
      >
        <Panel
          header={<div className="section-header-1">Security Data</div>}
          key="application-data"
        >
          <SecurityData />
        </Panel>
      </QfCollapse>
      <SupportingDocuments />
      <WorkflowSection
        activeStep={Slug.SECURITY}
        targetId={urlParams.assessmentId}
        code={WORKFLOW_CODE.CREDIT_WORKFLOW}
      />
    </>
  );
};

export default Security;
