import { LOADING_STATUS } from "@quest-finance/quest-fe-shared/dist/common/constants/loadingStatuses";
import { Action } from "redux";
import { WORKFLOW_STATE } from "../../constants/workflow";
import {
  WorkflowSummaryDetails,
  WorkflowResponse,
  WorkflowTask,
  WorkflowGroupTaskCount,
  WorkflowGroupResponse,
} from "../../types/Workflow";

export const GET_WORKFLOW_DETAILS = "GET_WORKFLOW_DETAILS";
export interface GetWorkflowDetails extends Action {
  type: typeof GET_WORKFLOW_DETAILS;
  targetId: string;
  state?: WORKFLOW_STATE | string;
}

export const GET_WORKFLOW_DETAILS_SUCCESS = "GET_WORKFLOW_DETAILS_SUCCESS";
export interface GetWorkflowDetailsSuccess extends Action {
  type: typeof GET_WORKFLOW_DETAILS_SUCCESS;
  workflowDetails: WorkflowResponse[];
}

export const GET_WORKFLOW_DETAILS_FAILED = "GET_WORKFLOW_DETAILS_FAILED";
export interface GetWorkflowDetailsFailed extends Action {
  type: typeof GET_WORKFLOW_DETAILS_FAILED;
  error: unknown;
}

export const RESET_WORKFLOW_DETAILS = "CE_RESET_WORKFLOW_DETAILS";
export interface ResetWorkflowDetails extends Action {
  type: typeof RESET_WORKFLOW_DETAILS;
}

export const RESET_WORKFLOW_GROUP_TASKS = "RESET_WORKFLOW_GROUP_TASKS";
export interface ResetWorkflowGroupTasks extends Action {
  type: typeof RESET_WORKFLOW_GROUP_TASKS;
}

export const UPDATE_WORKFLOW_TASK = "UPDATE_WORKFLOW_TASK";
export interface UpdateWorkflowTask {
  type: typeof UPDATE_WORKFLOW_TASK;
  taskId: number;
  taskResponse: WorkflowTask;
}

export const GET_WORKFLOW_SUMMARY = "GET_WORKFLOW_SUMMARY";
export interface GetWorkflowSummary {
  type: typeof GET_WORKFLOW_SUMMARY;
  targetId: string;
}

export const GET_WORKFLOW_SUMMARY_SUCCESS = "GET_WORKFLOW_SUMMARY_SUCCESS";
export interface GetWorkflowSummarySuccess {
  type: typeof GET_WORKFLOW_SUMMARY_SUCCESS;
  summaryResponse: WorkflowSummaryDetails;
  status: LOADING_STATUS;
}

export const GET_WORKFLOW_SUMMARY_FAILED = "GET_WORKFLOW_SUMMARY_FAILED";
export interface GetWorkflowSummaryFailed {
  type: typeof GET_WORKFLOW_SUMMARY_FAILED;
  error: unknown;
}

export const GET_WORKFLOW_GROUP_TASKS = "GET_WORKFLOW_GROUP_TASKS";
export interface GetWorkflowGroupTasks {
  type: typeof GET_WORKFLOW_GROUP_TASKS;
  targetId: string;
  code: string;
  groupCodes?: string | string[];
  state?: WORKFLOW_STATE[];
}

export const GET_WORKFLOW_GROUP_TASKS_SUCCESS =
  "GET_WORKFLOW_GROUP_TASKS_SUCCESS";
export interface GetWorkflowGroupTasksSuccess {
  type: typeof GET_WORKFLOW_GROUP_TASKS_SUCCESS;
  groupIds: number[];
  tasks: WorkflowTask[];
  count: WorkflowGroupTaskCount;
}

export const GET_WORKFLOW_GROUP_TASKS_FAILED =
  "GET_WORKFLOW_GROUP_TASKS_FAILED";
export interface GetWorkflowGroupTasksFailed {
  type: typeof GET_WORKFLOW_GROUP_TASKS_FAILED;
  error: unknown;
}

export const UPDATE_WORKFLOW_GROUP = "UPDATE_WORKFLOW_GROUP";
export interface UpdateWorkflowGroup {
  type: typeof UPDATE_WORKFLOW_GROUP;
  groupsResponse: WorkflowGroupResponse[];
}

export type WorkflowActions =
  | GetWorkflowDetails
  | GetWorkflowDetailsSuccess
  | GetWorkflowDetailsFailed
  | ResetWorkflowDetails
  | UpdateWorkflowTask
  | GetWorkflowSummary
  | GetWorkflowSummarySuccess
  | GetWorkflowSummaryFailed
  | GetWorkflowGroupTasks
  | GetWorkflowGroupTasksSuccess
  | GetWorkflowGroupTasksFailed
  | ResetWorkflowGroupTasks
  | UpdateWorkflowGroup;
