import APIService from "@quest-finance/quest-fe-shared/dist/common/services/APIService";
import { SingleResponse } from "@quest-finance/quest-fe-shared/dist/common/types/SingleResponse";
import * as types from "../types/ApprovalCondition";

class ApprovalCondition {
  public static async updateStatus(
    assessmentId: string,
    conditionId: string,
    status: types.Status
  ): Promise<SingleResponse<types.ApprovalCondition>> {
    const data: types.UpdateStatusParams = { status };
    const result = await APIService.jsonRequest<
      SingleResponse<types.ApprovalCondition>,
      types.UpdateStatusParams
    >(
      {
        method: "PATCH",
        path: `/assessment/assessments/${assessmentId}/approval-conditions/${conditionId}`,
        data,
      },
      true
    );
    return result;
  }
}

export default ApprovalCondition;
