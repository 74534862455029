import "./CreditReference.scss";
import React, { useEffect, useState } from "react";
import { joiResolver } from "@hookform/resolvers/joi";
import { DatePicker, Input, Button, Row, Col, message } from "antd";
import classNames from "classnames";
import moment from "moment";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router";
import { Currency } from "../../../common/components/Input/Input";
import { MOMENT_YEAR_MONTH_FORMAT } from "../../../common/contstants/date";
import { processError } from "../../../common/utils/error";
import {
  CREDIT_REFERENCE_DEFAULT_VALUE,
  FORM_FIELD_LABELS,
} from "../../constants/creditReference";
import CreditRefService from "../../services/CreditRefService";
import {
  CreditRefData,
  CreditRefAttributes,
} from "../../types/CreditReference";
import { creditReferenceSchema } from "../../validations/credit-reference";

type CreditReferenceProps = {
  refData?: CreditRefAttributes;
  onSubmitSuccess?: (arg: CreditRefAttributes, note: string) => void;
};

const CreditReference: React.FunctionComponent<CreditReferenceProps> = ({
  onSubmitSuccess,
  refData,
}: CreditReferenceProps) => {
  const { assessmentId } = useParams<{
    assessmentId: string;
  }>();
  const {
    control,
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: joiResolver(creditReferenceSchema),
    defaultValues: CREDIT_REFERENCE_DEFAULT_VALUE,
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (refData) {
      const fields = Object.keys(refData) as [keyof CreditRefData];
      fields.forEach((field) => {
        setValue(field, refData[field]);
      });
    }
  }, [refData, setValue]);

  const onSubmit = async (creditRef: CreditRefData & { notes: string }) => {
    setIsLoading(true);
    try {
      let creditRefDoc;
      if (!refData?.id) {
        const { data } = await CreditRefService.create(assessmentId, creditRef);
        creditRefDoc = data;
      } else {
        const { data } = await CreditRefService.update(
          refData.id,
          assessmentId,
          creditRef
        );
        creditRefDoc = data;
      }

      reset();
      if (onSubmitSuccess)
        onSubmitSuccess(creditRefDoc, creditRef?.notes as string);
    } catch (e) {
      processError(e, (errorMessage) => {
        message.error(errorMessage);
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="credit-ref">
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <Row gutter={[24, 8]}>
          <Col span={12}>
            <div className="field-label">{FORM_FIELD_LABELS.bank}</div>
            <input
              name="bank"
              autoComplete="off"
              ref={register}
              className={classNames("ant-input", { invalid: errors?.bank })}
            />
            <div className="validation">{errors?.bank?.message}</div>
          </Col>
          <Col span={12}>
            <div className="field-label">{FORM_FIELD_LABELS.start}</div>
            <Controller
              name="start"
              control={control}
              render={({ value, onChange }) => (
                <DatePicker
                  value={value ? moment(value, MOMENT_YEAR_MONTH_FORMAT) : null}
                  onChange={(_, dateString) => onChange(dateString)}
                  format={MOMENT_YEAR_MONTH_FORMAT}
                  placeholder={MOMENT_YEAR_MONTH_FORMAT.toLowerCase()}
                  picker="month"
                  className={classNames({
                    invalid: errors?.start,
                  })}
                />
              )}
            />
            <div className="validation">{errors?.start?.message}</div>
          </Col>
          <Col span={12}>
            <div className="field-label">
              {FORM_FIELD_LABELS.term} (in months)
            </div>
            <input
              name="term"
              autoComplete="off"
              ref={register}
              className={classNames("ant-input", { invalid: errors?.term })}
            />
            <div className="validation">{errors?.term?.message}</div>
          </Col>
          <Col span={12}>
            <div className="field-label">{FORM_FIELD_LABELS.asset}</div>
            <input
              name="asset"
              autoComplete="off"
              ref={register}
              className={classNames("ant-input", { invalid: errors?.asset })}
            />
            <div className="validation">{errors?.asset?.message}</div>
          </Col>
          <Col span={12}>
            <div className="field-label">{FORM_FIELD_LABELS.naf}</div>
            <Controller
              name="naf"
              control={control}
              render={({ value, onChange }) => (
                <Currency
                  value={value}
                  onValueChange={({ value }) => onChange(value)}
                  className={classNames({
                    invalid: errors?.naf,
                  })}
                />
              )}
            />
            <div className="validation">{errors?.naf?.message}</div>
          </Col>
          <Col span={12}>
            <div className="field-label">{FORM_FIELD_LABELS.paymentAmount}</div>
            <Controller
              name="paymentAmount"
              control={control}
              render={({ value, onChange }) => (
                <Currency
                  value={value}
                  onValueChange={({ value }) => onChange(value)}
                  className={classNames({
                    invalid: errors?.paymentAmount,
                  })}
                />
              )}
            />
            <div className="validation">{errors?.paymentAmount?.message}</div>
          </Col>
          <Col span={12}>
            <div className="field-label">{FORM_FIELD_LABELS.balloon}</div>
            <Controller
              name="balloon"
              control={control}
              render={({ value, onChange }) => (
                <Currency
                  value={value}
                  onValueChange={({ value }) => onChange(value)}
                  className={classNames({
                    invalid: errors?.balloon,
                  })}
                />
              )}
            />
            <div className="validation">{errors?.balloon?.message}</div>
          </Col>
          <Col span={12}>
            <div className="field-label">
              {FORM_FIELD_LABELS.outstandingBalance}
            </div>
            <Controller
              name="outstandingBalance"
              control={control}
              render={({ value, onChange }) => (
                <Currency
                  value={value}
                  onValueChange={({ value }) => onChange(value)}
                  className={classNames({
                    invalid: errors?.outstandingBalance,
                  })}
                />
              )}
            />
            <div className="validation">
              {errors?.outstandingBalance?.message}
            </div>
          </Col>
          <Col span={24}>
            <div className="field-label">{FORM_FIELD_LABELS.conduct}</div>
            <input
              name="conduct"
              autoComplete="off"
              ref={register}
              className={classNames("ant-input", {
                invalid: errors?.conduct,
              })}
            />
            <div className="validation">{errors?.conduct?.message}</div>
          </Col>
          <Col span={24}>
            <div className="field-label">{FORM_FIELD_LABELS.notes}</div>
            <Controller
              name="notes"
              control={control}
              render={({ value, onChange }) => (
                <Input.TextArea
                  value={value}
                  onChange={onChange}
                  maxLength={500}
                  autoSize={{ minRows: 1, maxRows: 6 }}
                  showCount
                />
              )}
            />
          </Col>
        </Row>
        <Button
          htmlType="submit"
          loading={isLoading}
          className="submit qf-btn-green"
        >
          Save
        </Button>
      </form>
    </div>
  );
};

export const Test: React.FC = (props) => {
  return <DatePicker format="MM/YYYY" picker="month" />;
};
export default CreditReference;
