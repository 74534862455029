import { LOADING_STATUS } from "@quest-finance/quest-fe-shared/dist/common/constants/loadingStatuses";
import { WORKFLOW_STATE } from "../../constants/workflow";
import {
  WorkflowSummaryDetails,
  WorkflowResponse,
  WorkflowTask,
  WorkflowGroupTaskCount,
  WorkflowGroupResponse,
} from "../../types/Workflow";
import * as actionTypes from "../types/workflow";

export const getWorkflowDetails = (
  targetId: string,
  state?: WORKFLOW_STATE | string
): actionTypes.GetWorkflowDetails => ({
  type: actionTypes.GET_WORKFLOW_DETAILS,
  targetId,
  state,
});

export const getWorkflowDetailsSuccess = (
  workflowDetails: WorkflowResponse[]
): actionTypes.GetWorkflowDetailsSuccess => ({
  type: actionTypes.GET_WORKFLOW_DETAILS_SUCCESS,
  workflowDetails,
});

export const getWorkflowDetailsFailed = (
  error: unknown
): actionTypes.GetWorkflowDetailsFailed => ({
  type: actionTypes.GET_WORKFLOW_DETAILS_FAILED,
  error,
});

export const resetWorkflowDetails = (): actionTypes.ResetWorkflowDetails => ({
  type: actionTypes.RESET_WORKFLOW_DETAILS,
});

export const updateWorkflowTask = (
  taskId: number,
  taskResponse: WorkflowTask
): actionTypes.UpdateWorkflowTask => ({
  type: actionTypes.UPDATE_WORKFLOW_TASK,
  taskId,
  taskResponse,
});

export const getWorkflowSummary = (
  targetId: string
): actionTypes.GetWorkflowSummary => ({
  type: actionTypes.GET_WORKFLOW_SUMMARY,
  targetId,
});

export const getWorkflowSummarySuccess = (
  summaryResponse: WorkflowSummaryDetails
): actionTypes.GetWorkflowSummarySuccess => ({
  type: actionTypes.GET_WORKFLOW_SUMMARY_SUCCESS,
  summaryResponse,
  status: LOADING_STATUS.SUCCESS,
});

export const getWorkflowSummaryFailed = (
  error: unknown
): actionTypes.GetWorkflowSummaryFailed => ({
  type: actionTypes.GET_WORKFLOW_SUMMARY_FAILED,
  error,
});

export const updateWorkflowGroup = (
  groupsResponse: WorkflowGroupResponse[]
): actionTypes.UpdateWorkflowGroup => ({
  type: actionTypes.UPDATE_WORKFLOW_GROUP,
  groupsResponse,
});

export const getWorkflowGroupTasks = (
  targetId: string,
  code: string,
  groupCodes?: string | string[],
  state?: WORKFLOW_STATE[]
): actionTypes.GetWorkflowGroupTasks => ({
  type: actionTypes.GET_WORKFLOW_GROUP_TASKS,
  targetId,
  code,
  groupCodes,
  state,
});

export const getWorkflowGroupTasksSuccess = (
  groupIds: number[],
  tasks: WorkflowTask[],
  count: WorkflowGroupTaskCount
): actionTypes.GetWorkflowGroupTasksSuccess => ({
  type: actionTypes.GET_WORKFLOW_GROUP_TASKS_SUCCESS,
  groupIds,
  tasks,
  count,
});

export const getWorkflowGroupTasksFailed = (
  error: unknown
): actionTypes.GetWorkflowGroupTasksFailed => ({
  type: actionTypes.GET_WORKFLOW_GROUP_TASKS_FAILED,
  error,
});

export const resetWorkflowGroupTasks = (): actionTypes.ResetWorkflowGroupTasks => ({
  type: actionTypes.RESET_WORKFLOW_GROUP_TASKS,
});
