import {
  WorkflowGroupResponse,
  WorkflowGroupTaskCount,
  WorkflowTask,
} from "../types/Workflow";

export const extractWorkflowGroup = (
  data: WorkflowGroupResponse[]
): {
  tasks: WorkflowTask[];
  count: WorkflowGroupTaskCount;
  groupIds: number[];
} => {
  const groupIds: number[] = [];
  let tasks: WorkflowTask[] = [];
  let count: WorkflowGroupTaskCount | null = null;

  data.forEach((data) => {
    tasks = [...tasks, ...data.tasks];
    groupIds.push(data.groupId);
  });
  const groupCount = data
    .map((group) => group.count)
    .reduce((previous, current) => {
      return {
        DECLINED: previous.DECLINED + current.DECLINED,
        HOLD: previous.HOLD + current.HOLD,
        PASS: previous.PASS + current.PASS,
        PENDING: previous.PENDING + current.PENDING,
        REFER: previous.REFER + current.REFER,
        TOTAL: previous.TOTAL + current.TOTAL,
      };
    });
  count = groupCount;

  return {
    groupIds,
    tasks,
    count,
  };
};
