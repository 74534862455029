import "./ApprovalConditions.scss";
import React, { useCallback, useEffect, useState } from "react";
import {
  Collapse,
  Form,
  Input,
  Checkbox,
  Row,
  Col,
  Button,
  message,
} from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useSelector } from "react-redux";
import {
  dealerSaleConditions,
  privateSaleConditions,
} from "../../../assessment/constants/approvalConditions";
import { processError } from "../../../common/utils/error";
import { QfCollapse } from "../../../theme";
import { assessmentResponseSelector } from "../../selectors/assessmentDetail";
import AssessmentService from "../../services/AssessmentService";
import { AssessmentDetail } from "../../types/AssessmentDetail";

const { Panel } = Collapse;

const defaultConditions = [...dealerSaleConditions, ...privateSaleConditions];

type ApprovalConditionsProps = {
  onConditionsUpdate?: () => void;
};
const ApprovalConditions: React.FunctionComponent<ApprovalConditionsProps> = ({
  onConditionsUpdate,
}: ApprovalConditionsProps) => {
  const assessment: AssessmentDetail = useSelector(assessmentResponseSelector);
  const [submitApprovalConditions, setSubmitApprovalConditions] = useState(
    false
  );
  const { approvalConditions: existingConditions } = assessment;
  const [newCondition, setNewCondition] = useState("");
  const [approvalConditions, setApprovalConditions] = useState<
    {
      isChecked: boolean;
      condition: string;
    }[]
  >([]);

  const onConditionsUpdateCallback = useCallback(
    async (conditions: string[]) => {
      setSubmitApprovalConditions(true);
      try {
        await AssessmentService.submitApprovalConditions(assessment.id, {
          conditions,
        });

        setSubmitApprovalConditions(false);
        if (onConditionsUpdate) onConditionsUpdate();
      } catch (e) {
        setSubmitApprovalConditions(false);
        processError(e, (errorMessage) => {
          message.error(errorMessage);
        });
      }
    },
    [assessment, onConditionsUpdate]
  );

  useEffect(() => {
    const conditionsFromServer = existingConditions.map(
      (aprovalCondition) => aprovalCondition.condition
    );
    let selectedConditions = [...defaultConditions, ...conditionsFromServer];
    selectedConditions = selectedConditions.filter((condition, index) => {
      return selectedConditions.indexOf(condition) === index;
    });
    setApprovalConditions(
      selectedConditions.map((condition) => {
        const isChecked = conditionsFromServer.indexOf(condition) !== -1;
        return {
          condition,
          isChecked,
        };
      })
    );
  }, [existingConditions]);

  const handleAddMore = () => {
    setApprovalConditions((previousData) => [
      ...previousData,
      {
        condition: newCondition,
        isChecked: true,
      },
    ]);
    setNewCondition("");
  };

  const handleConditionsChange = (
    index: number,
    fieldName: string,
    newValue: string | boolean
  ) => {
    const newConditions = [...approvalConditions];
    let condition = Object.assign({}, newConditions[index]);
    condition = {
      ...condition,
      [fieldName]: newValue,
    };
    newConditions[index] = condition;
    setApprovalConditions(newConditions);
  };

  const handleConditionsUpdate = () => {
    const selectedConditions = approvalConditions
      .filter((condition) => condition.isChecked)
      .map((condition) => condition.condition);

    onConditionsUpdateCallback(selectedConditions);
  };

  return (
    <QfCollapse
      defaultActiveKey={["1"]}
      className="approval-conditions-wrapper"
      expandIconPosition="right"
      ghost
    >
      <Panel
        header={<div className="section-header-1">Approval Conditions</div>}
        key="1"
      >
        <div className="approval-conditions">
          <Row gutter={16}>
            {approvalConditions.map((condition, index) => (
              <Col sm={24} md={12} key={index}>
                <Input.Group compact>
                  <Checkbox
                    style={{ width: "5%" }}
                    checked={condition.isChecked}
                    onChange={(event: CheckboxChangeEvent) => {
                      const { checked } = event.target;
                      handleConditionsChange(index, "isChecked", checked);
                    }}
                    disabled={submitApprovalConditions}
                  />
                  <Input
                    value={condition.condition}
                    name={`conditions[${index}].condition`}
                    style={{ width: "95%" }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const { value } = event.target;
                      handleConditionsChange(index, "condition", value);
                    }}
                    disabled={submitApprovalConditions}
                  />
                </Input.Group>
              </Col>
            ))}
          </Row>
        </div>
        <div className="approval-conditions-add">
          <span className="f-quest-navy f-bold mb-2 d-block">
            Add approval conditions
          </span>
          <Form layout="inline">
            <Form.Item>
              <Input
                name="newCondition"
                value={newCondition}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const { value } = event.target;
                  setNewCondition(value);
                }}
                disabled={submitApprovalConditions}
              />
            </Form.Item>
            <Form.Item>
              <Button
                className="qf-btn-green"
                onClick={handleAddMore}
                loading={submitApprovalConditions}
              >
                Add
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                className="qf-btn-green"
                onClick={handleConditionsUpdate}
                loading={submitApprovalConditions}
              >
                Update Conditions
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Panel>
    </QfCollapse>
  );
};

export default ApprovalConditions;
