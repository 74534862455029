import { ListResponse } from "@quest-finance/quest-fe-shared/dist/common/types/ListResponse";
import { DocumentService } from "@quest-finance/quest-fe-shared/dist/files";
import { message } from "antd";
import * as queryString from "query-string";
import { put, call, ForkEffect, takeLatest } from "redux-saga/effects";
import { processError } from "../../common/utils/error";
import * as actionCreators from "../actions/creators/documentList";
import * as actionTypes from "../actions/types/documentList";
import { AssessmentDocument } from "../types/AssessmentDocument";

function* getDocumentListWorker({
  assessmentId,
  purpose,
  limit,
  offset,
}: actionTypes.GetAssessmentDocuments) {
  try {
    const queryParams = queryString.stringify({
      purpose,
      limit,
      offset,
    });
    const result: ListResponse<AssessmentDocument> = yield call(
      DocumentService.getDocumentList,
      `/assessment/assessments/${assessmentId}/documents?${queryParams}`
    );
    yield put(actionCreators.getAssessmentDocumentsSuccess(result));
  } catch (e) {
    processError(e, (errorMessage) => {
      message.error(errorMessage);
    });
    yield put(actionCreators.getAssessmentDocumentsFailed());
  } finally {
    yield put(actionCreators.getAssessmentDocumentsCompleted());
  }
}

function* watchAdminData(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(actionTypes.GET_ASSESSMENT_DOCUMENTS, getDocumentListWorker);
}

export default watchAdminData;
