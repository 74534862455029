import { ListResponse } from "@quest-finance/quest-fe-shared/dist/common/types/ListResponse";
import { message } from "antd";
import { call, ForkEffect, put, takeLatest } from "redux-saga/effects";
import { processError } from "../../common/utils/error";
import * as actionCreators from "../actions/creators/taskNote";
import * as actionTypes from "../actions/types/taskNote";
import TaskNoteService from "../services/TaskNoteService";
import { TaskNoteAttributes } from "../types/TaskNote";

function* getTaskNotesWorker({ taskId, payload }: actionTypes.GetTaskNotes) {
  try {
    const result: ListResponse<TaskNoteAttributes> = yield call(
      TaskNoteService.getList,
      taskId,
      payload
    );
    yield put(actionCreators.getTaskNotesSuccess(result));
  } catch (error) {
    yield put(actionCreators.getTaskNotesFailed());
    processError(error, (errorMessage) => {
      message.error(errorMessage);
    });
  } finally {
    yield put(actionCreators.getTaskNotesCompleted());
  }
}

function* watchTaskNote(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(actionTypes.GET_TASK_NOTES, getTaskNotesWorker);
}

export default watchTaskNote;
