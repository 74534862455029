import "./WorkflowList.scss";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { LOADING_STATUS } from "@quest-finance/quest-fe-shared/dist/common/constants/loadingStatuses";
import { Collapse, Button, Empty, Spin, Tooltip, message } from "antd";
import lodash from "lodash";
import { useSelector } from "react-redux";
import { workflowSelectors } from "../..";
import { processError } from "../../../common/utils/error";
import { QfCollapse } from "../../../theme";
import {
  getWorkflowGroupTasks,
  getWorkflowSummary,
  resetWorkflowGroupTasks,
} from "../../actions/creators/workflow";
import { WORKFLOW_STATE } from "../../constants/workflow";
import { useWorkflowDispatch } from "../../dispatchers";
import WorkflowService from "../../services/WorkflowService";
import { WorkflowGroupTaskCount, WorkflowResponse } from "../../types/Workflow";
import WorkflowItem from "../workflow-item/WorkflowItem";

const { Panel } = Collapse;

type WorkflowHeaderProps = {
  count: WorkflowGroupTaskCount | null;
  onReRunClick: () => void;
  filterTasks: (state: WORKFLOW_STATE[]) => void;
  reRunLoading: boolean;
};
const WorkflowHeader: React.FunctionComponent<WorkflowHeaderProps> = ({
  count = null,
  onReRunClick,
  reRunLoading,
  filterTasks,
}: WorkflowHeaderProps) => {
  const reRunWorkflow = useCallback(() => {
    onReRunClick();
  }, [onReRunClick]);

  return (
    <div className="workflow-header">
      <h3>Workflow</h3>
      {count && (
        <div className="filters">
          <Tooltip title="Decline">
            <div
              className="state-btn decline"
              onClick={() => filterTasks([WORKFLOW_STATE.DECLINED])}
            >
              {lodash.get(count, "DECLINED", 0)}
            </div>
          </Tooltip>
          <Tooltip title="Pending & On Hold">
            <div
              className="state-btn incomplete"
              onClick={() =>
                filterTasks([WORKFLOW_STATE.HOLD, WORKFLOW_STATE.PENDING])
              }
            >
              {lodash.get(count, "PENDING", 0) + lodash.get(count, "HOLD", 0)}
            </div>
          </Tooltip>
          <Tooltip title="Refer">
            <div
              className="state-btn refer"
              onClick={() => filterTasks([WORKFLOW_STATE.REFER])}
            >
              {lodash.get(count, "REFER", 0)}
            </div>
          </Tooltip>
          <Tooltip title="All">
            <div className="state-btn all" onClick={() => filterTasks([])}>
              {lodash.get(count, "TOTAL", 0)}
            </div>
          </Tooltip>
        </div>
      )}
      <div className="rerun-wrapper">
        {count && (
          <Button
            loading={reRunLoading}
            className="qf-btn-green rerun-btn"
            onClick={() => reRunWorkflow()}
          >
            Rerun items
          </Button>
        )}
      </div>
    </div>
  );
};

type WorkflowListProps = {
  targetId: string;
  code: string;
  groupCodes?: string | string[];
};
const WorkflowList: React.FunctionComponent<WorkflowListProps> = ({
  targetId,
  code,
  groupCodes,
}: WorkflowListProps) => {
  const [filter, setFilter] = useState<WORKFLOW_STATE[]>([]);
  const workflowDispatch = useWorkflowDispatch();
  const [reRunLoading, setReRunLoading] = useState(false);
  const workflows = useSelector(workflowSelectors.workflowDetailsSelector);
  const selectedWorkflow = useMemo(() => {
    return workflows.find((workflow) => workflow.code === code);
  }, [workflows, code]) as WorkflowResponse;
  const tasks = useSelector(workflowSelectors.workflowGroupTasksSelector);
  const count = useSelector(workflowSelectors.workflowGroupTasksCountSelector);
  const taskStatus = useSelector(
    workflowSelectors.workflowGroupTasksStatusSelector
  );
  const taskError = useSelector(
    workflowSelectors.workflowGroupTasksErrorSelector
  );

  const groupIds = useSelector(workflowSelectors.workflowGroupIdsSelector);

  const getWorkflowTasks = useCallback(
    async (targetId, code, groupCodes, filter) => {
      workflowDispatch(
        getWorkflowGroupTasks(targetId, code, groupCodes, filter)
      );
    },
    [workflowDispatch]
  );

  useEffect(() => {
    if (taskStatus === LOADING_STATUS.FAILED) {
      processError(taskError, (errorMessage) => {
        message.error(errorMessage);
      });
    }
  }, [taskStatus, taskError]);

  useEffect(() => {
    getWorkflowTasks(targetId, code, groupCodes, filter);

    return () => {
      workflowDispatch(resetWorkflowGroupTasks());
    };
  }, [workflowDispatch, getWorkflowTasks, groupCodes, code, targetId, filter]);

  const handleWorkflowFilter = (filter: WORKFLOW_STATE[]) => {
    setFilter(filter);
  };

  const reRunWorkflow = async () => {
    setReRunLoading(true);
    try {
      await WorkflowService.reRun(selectedWorkflow.id, targetId, groupIds);
      // workflowDispatch(updateWorkflowGroup(result.data));
      setFilter([]);

      message.success("Successfully re-running the workflow group.");
    } catch (error) {
      processError(error, (errorMessage) => {
        message.error(errorMessage);
      });
    } finally {
      setReRunLoading(false);
    }
  };

  const onSuccessTaskUpdate = () => {
    workflowDispatch(getWorkflowSummary(targetId));
    getWorkflowTasks(targetId, code, groupCodes, filter);
  };

  return (
    <QfCollapse
      defaultActiveKey={["workflow"]}
      className="workflow-wrapper"
      expandIconPosition="right"
      ghost
      collapsible="disabled"
    >
      <Panel
        header={
          <WorkflowHeader
            count={count}
            onReRunClick={reRunWorkflow}
            reRunLoading={reRunLoading}
            filterTasks={handleWorkflowFilter}
          />
        }
        key={"workflow"}
        className="workflow-list"
      >
        {count ? (
          <Spin
            spinning={taskStatus === LOADING_STATUS.LOADING || reRunLoading}
          >
            <table className="workflow-list-table mb-3">
              <thead>
                <tr>
                  <th style={{ width: "170px" }}>Quick actions</th>
                  <th style={{ width: "30%" }}>Workflow item</th>
                  <th style={{ width: "25%" }}>Value</th>
                  <th>Last note</th>
                </tr>
              </thead>
              <tbody>
                {tasks.length === 0 && (
                  <tr>
                    <td colSpan={5}>
                      <Empty description="No workflow tasks found" />
                    </td>
                  </tr>
                )}
                {selectedWorkflow &&
                  tasks
                    .filter((task) => task.state !== WORKFLOW_STATE.DISABLED)
                    .map((task) => (
                      <WorkflowItem
                        targetId={targetId}
                        filter={filter}
                        code={code}
                        groupCodes={groupCodes}
                        key={task.id}
                        workflowId={selectedWorkflow.id}
                        task={task}
                        onUpdateTaskSuccess={onSuccessTaskUpdate}
                      />
                    ))}
              </tbody>
            </table>
          </Spin>
        ) : (
          <Empty description="No workflow tasks found" />
        )}
      </Panel>
    </QfCollapse>
  );
};

export default WorkflowList;
