import React, { useEffect, useState } from "react";
import { WorkflowList } from "../../../workflow";
import { Slug } from "../../types/Navigation";

type WorkflowSectionProps = {
  targetId: string;
  code: string;
  activeStep: string;
};
const WorkflowSection: React.FunctionComponent<WorkflowSectionProps> = ({
  targetId,
  code,
  activeStep,
}: WorkflowSectionProps) => {
  const [groupCodes, setGroupCodes] = useState<string | string[]>("");

  useEffect(() => {
    if (activeStep !== Slug.DECISION) {
      if (activeStep === Slug.APPLICANT_GUARANTORS) {
        setGroupCodes([
          "APPLICANT",
          "GUARANTOR",
          "APPLICANT_EQUIFAX",
          "GUARANTOR_SME_EQUIFAX",
          "GUARANTOR_INDIVIDUAL_EQUIFAX",
          "GUARANTOR_ILLION",
          "GUARANTOR_FRANKIEONE",
        ]);
      } else {
        setGroupCodes(activeStep.toUpperCase());
      }
    }
  }, [activeStep]);

  return (
    <div className="workflow-section mt-3">
      <WorkflowList targetId={targetId} code={code} groupCodes={groupCodes} />
    </div>
  );
};

export default WorkflowSection;
