import { LOADING_STATUS } from "@quest-finance/quest-fe-shared/dist/common/constants/loadingStatuses";
import * as workflowActions from "../actions/types/workflow";
import { WorkflowDetailsState } from "../types/Workflow";
import { extractWorkflowGroup } from "../utils/workflow";

export const workflowDetails: WorkflowDetailsState = {
  workflowDetails: {
    data: [],
    status: LOADING_STATUS.IDLE,
  },
  workflowSummaryDetails: {
    data: null,
    status: LOADING_STATUS.IDLE,
  },
  workflowTasks: {
    data: {
      groupIds: [],
      count: null,
      tasks: [],
    },
    status: LOADING_STATUS.IDLE,
  },
};

export const reducer = (
  state: WorkflowDetailsState = workflowDetails,
  action: workflowActions.WorkflowActions
): WorkflowDetailsState => {
  switch (action.type) {
    case workflowActions.RESET_WORKFLOW_DETAILS: {
      return {
        ...workflowDetails,
      };
    }
    case workflowActions.GET_WORKFLOW_DETAILS: {
      return {
        ...state,
        workflowDetails: {
          ...state.workflowDetails,
          status: LOADING_STATUS.LOADING,
          error: undefined,
        },
      };
    }
    case workflowActions.GET_WORKFLOW_DETAILS_SUCCESS: {
      return {
        ...state,
        workflowDetails: {
          status: LOADING_STATUS.SUCCESS,
          data: action.workflowDetails,
        },
      };
    }
    case workflowActions.GET_WORKFLOW_DETAILS_FAILED: {
      return {
        ...state,
        workflowDetails: {
          ...state.workflowDetails,
          status: LOADING_STATUS.FAILED,
          error: action.error,
        },
      };
    }
    case workflowActions.UPDATE_WORKFLOW_TASK: {
      const tasks = [...state.workflowTasks.data.tasks];
      const newTask = action.taskResponse;
      const taskIndex = tasks.findIndex((task) => task.id === newTask.id);
      if (taskIndex !== -1) {
        tasks[taskIndex] = {
          ...tasks[taskIndex],
          ...newTask,
        };

        return {
          ...state,
          workflowTasks: {
            ...state.workflowTasks,
            data: {
              ...state.workflowTasks.data,
              tasks,
            },
          },
        };
      }

      return {
        ...state,
      };
    }
    case workflowActions.GET_WORKFLOW_SUMMARY: {
      return {
        ...state,
        workflowSummaryDetails: {
          ...state.workflowSummaryDetails,
          status: LOADING_STATUS.LOADING,
          error: undefined,
        },
      };
    }
    case workflowActions.GET_WORKFLOW_SUMMARY_SUCCESS: {
      return {
        ...state,
        workflowSummaryDetails: {
          data: action.summaryResponse,
          status: action.status,
        },
      };
    }
    case workflowActions.GET_WORKFLOW_SUMMARY_FAILED: {
      return {
        ...state,
        workflowSummaryDetails: {
          ...state.workflowSummaryDetails,
          status: LOADING_STATUS.FAILED,
          error: action.error,
        },
      };
    }
    case workflowActions.GET_WORKFLOW_GROUP_TASKS: {
      return {
        ...state,
        workflowTasks: {
          ...state.workflowTasks,
          status: LOADING_STATUS.LOADING,
          error: undefined,
        },
      };
    }
    case workflowActions.GET_WORKFLOW_GROUP_TASKS_SUCCESS: {
      return {
        ...state,
        workflowTasks: {
          status: LOADING_STATUS.SUCCESS,
          data: {
            groupIds: action.groupIds,
            count: action.count,
            tasks: action.tasks,
          },
        },
      };
    }
    case workflowActions.GET_WORKFLOW_GROUP_TASKS_FAILED: {
      return {
        ...state,
        workflowTasks: {
          ...state.workflowTasks,
          data: {
            tasks: [],
            count: null,
            groupIds: [],
          },
          status: LOADING_STATUS.FAILED,
          error: action.error,
        },
      };
    }
    case workflowActions.RESET_WORKFLOW_GROUP_TASKS: {
      return {
        ...state,
        workflowTasks: {
          data: {
            tasks: [],
            count: null,
            groupIds: [],
          },
          status: LOADING_STATUS.IDLE,
          error: undefined,
        },
      };
    }
    case workflowActions.UPDATE_WORKFLOW_GROUP: {
      const { tasks, groupIds, count } = extractWorkflowGroup(
        action.groupsResponse
      );

      return {
        ...state,
        workflowTasks: {
          ...state.workflowTasks,
          data: {
            tasks,
            groupIds,
            count,
          },
        },
      };
    }
    default: {
      return state;
    }
  }
};
