import React, { useEffect, useState } from "react";
import { FormOutlined } from "@ant-design/icons";
import {
  ApplicationResponse,
  getApplicationSelector,
  useApplicationFormDispatch,
  saveApplicantSuccess,
  ApplicantRequest,
} from "@quest-finance/quest-fe-shared/dist/application";
import {
  INDUSTRY_LABELS,
  INDUSTRY_OPTIONS,
} from "@quest-finance/quest-fe-shared/dist/application/constants/industryTypes";
import {
  TRUSTEE_TYPES,
  TRUSTEE_TYPE_LABELS,
} from "@quest-finance/quest-fe-shared/dist/application/constants/trusteeTypes"; // to be exposed in fe-shared
import { ApplicantResponse } from "@quest-finance/quest-fe-shared/dist/application/types/ApplicantResponse";
import {
  ENTITY_TYPES,
  ENTITY_TYPE_LABELS,
} from "@quest-finance/quest-fe-shared/dist/common/constants/entityTypes";
import { Dictionary } from "@quest-finance/quest-fe-shared/dist/common/types/Dictionary";
import { dateFormat } from "@quest-finance/quest-fe-shared/dist/common/utils/date";
import { Input, Row, Col, Skeleton, Collapse, Button, message } from "antd";
import { differenceInMonths } from "date-fns";
import _ from "lodash";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import {
  LONG_DATE_FORMAT,
  NOT_AVAILABLE,
} from "../../../common/contstants/app";
import { processError } from "../../../common/utils/error";
import { roundHalf } from "../../../common/utils/number";
import { QfCollapse } from "../../../theme";
import ApplicantService from "../../services/ApplicantService";
import CreditScore from "./credit-score/CreditScore";
import "./Applicant.scss";

const { Panel } = Collapse;

const displayRegisteredDate = (date: string) => {
  const dateMonthDiff = differenceInMonths(new Date(), new Date(date));

  let dateDuration: string | number = Math.floor(dateMonthDiff) + " months";
  if (dateMonthDiff >= 24) {
    dateDuration = dateMonthDiff / 12;
    dateDuration = roundHalf(dateDuration, true) + " yrs";
  }

  return dateDuration;
};

const Applicant: React.FunctionComponent = () => {
  const { assessmentId } = useParams<{
    assessmentId: string;
  }>();
  const dispatchApplication = useApplicationFormDispatch();
  const application: ApplicationResponse = useSelector(getApplicationSelector);
  const applicant = application.applicant as ApplicantResponse;
  let content = <Skeleton active />;
  const [applicantDetails, setApplicantDetails] = useState<
    Record<string, string>
  >({});

  const saveApplicantDetails = async () => {
    try {
      const { data } = await ApplicantService.update(
        application.id,
        (applicantDetails as unknown) as ApplicantRequest
      );

      dispatchApplication(
        saveApplicantSuccess({
          ...application.applicant,
          ...data,
        } as ApplicantResponse)
      );
      message.success("Applicant details successfully saved.");
    } catch (error) {
      processError(error, (errorMessage) => {
        message.error(errorMessage);
      });
    }
  };

  useEffect(() => {
    if (applicant.entityId && _.isEmpty(applicantDetails)) {
      setApplicantDetails({
        natureOfBusiness: applicant.natureOfBusiness || "",
      });
    }
  }, [applicant, applicantDetails]);

  if (applicant.entityId) {
    const {
      entityId,
      abn,
      abnRegisteredDate,
      gstRegisteredDate,
      entityName,
      tradingName,
      entityType,
      trusteeType,
      trusteeAcn,
      trusteeName,
      phone,
      industry,
      industryType,
      addressUnitNumber,
      addressStreetNumber,
      addressStreetName,
      addressState,
      addressSuburb,
      addressPostcode,
    } = applicant;
    const industryOpts = INDUSTRY_OPTIONS[industry] as Dictionary;
    const applicantName = tradingName
      ? `${entityName} (t/a ${tradingName})`
      : entityName;
    const showApplicant =
      entityType === ENTITY_TYPES.COMPANY ||
      trusteeType === TRUSTEE_TYPES.COMPANY;

    const abnRegDateDuration = displayRegisteredDate(
      abnRegisteredDate as string
    );
    const gstRegDateDuration = displayRegisteredDate(
      gstRegisteredDate as string
    );

    content = (
      <QfCollapse
        activeKey={["applicant"]}
        className="collapsible"
        expandIconPosition="right"
        ghost
      >
        <Panel
          header={applicantName}
          className="panel application-data"
          key="applicant"
          showArrow={false}
        >
          {showApplicant && (
            <CreditScore
              assessmentId={assessmentId}
              applicantEntityId={entityId}
            />
          )}
          <div className="section-header">Applicant data</div>
          <Row className="info" gutter={[16, 16]}>
            <Col span={12}>
              <div className="label">Type of entity</div>
              {TRUSTEE_TYPE_LABELS[trusteeType as string]}{" "}
              {ENTITY_TYPE_LABELS[entityType]}
            </Col>
            <Col span={12}></Col>
            {entityType === ENTITY_TYPES.TRUST && (
              <>
                <Col span={12}>
                  <div className="label">Trustee ACN</div>
                  <span className="value">{trusteeAcn || NOT_AVAILABLE}</span>
                </Col>
                <Col span={12}>
                  <div className="label">Trustee name</div>
                  <span className="value">{trusteeName}</span>
                </Col>
              </>
            )}
            <Col span={12}>
              <div className="label">ABN</div>
              <span className="value">{abn}</span>
            </Col>
            <Col span={12}>
              <div className="label">ABN registered date</div>
              <span className="value">
                {dateFormat(
                  new Date(abnRegisteredDate as string),
                  LONG_DATE_FORMAT
                )}{" "}
                ({abnRegDateDuration})
              </span>
            </Col>
            <Col span={12}>
              <div className="label">Trading name</div>
              <span className="value">{tradingName}</span>
            </Col>
            <Col span={12}>
              <div className="label">Type of Entity</div>
              <div className="value">{ENTITY_TYPE_LABELS[entityType]}</div>
            </Col>
            <Col span={12}>
              <div className="label">ACN</div>
              <div className="value">
                {((abn || "") as string).substring(2) || NOT_AVAILABLE}
              </div>
            </Col>
            <Col span={12}>
              <div className="label">GST registered date</div>
              {gstRegisteredDate ? (
                <div className="value">
                  {dateFormat(new Date(gstRegisteredDate), LONG_DATE_FORMAT)} (
                  {gstRegDateDuration})
                </div>
              ) : (
                NOT_AVAILABLE
              )}
            </Col>
            <Col span={24}>
              <div className="label">Industry</div>
              <div className="value">{INDUSTRY_LABELS[industry]}</div>
            </Col>
            <Col span={24}>
              <div className="label">Industry Type</div>
              <div className="value">{industryOpts[industryType]}</div>
            </Col>
            <Col span={12}>
              <div className="label">Business phone</div>
              <div className="value">{phone}</div>
            </Col>
            <Col span={12}>
              <div className="label">Principle place of business address</div>
              <div className="value">
                {addressUnitNumber ? `${addressUnitNumber}/` : ""}
                {[
                  addressStreetNumber,
                  addressStreetName,
                  addressState,
                  addressSuburb,
                  addressPostcode,
                ].join(" ")}
              </div>
            </Col>
            <Col span={24}>
              <div className="label">Nature of business</div>
              <Input.TextArea
                value={applicantDetails.natureOfBusiness}
                autoSize={{ minRows: 2, maxRows: 5 }}
                maxLength={500}
                showCount
                onChange={(e) =>
                  setApplicantDetails((data) => ({
                    ...data,
                    natureOfBusiness: e.target.value,
                  }))
                }
              />
            </Col>
            <Col span={24}>
              <Button className="qf-btn-green" onClick={saveApplicantDetails}>
                Save
              </Button>
            </Col>
          </Row>
        </Panel>
      </QfCollapse>
    );
  }

  return (
    <div className="applicant">
      <div className="header">
        <div className="label">Applicant</div>
        <Link to={`/application/applications/${application.id}/applicant`}>
          <FormOutlined className="edit-link" />
        </Link>
      </div>
      {content}
    </div>
  );
};

export default Applicant;
