import { SingleResponse } from "@quest-finance/quest-fe-shared/dist/common/types/SingleResponse";
import { message } from "antd";
import { put, call, ForkEffect, takeLatest } from "redux-saga/effects";
import { processError } from "../../common/utils/error";
import * as actionCreators from "../actions/creators/assessmentDetail";
import * as actionTypes from "../actions/types/assessmentDetail";
import AssessmentService from "../services/AssessmentService";
import { AssessmentDetail } from "../types/AssessmentDetail";

function* getAssessmentDetailWorker({
  assessmentId,
}: actionTypes.GetAssessmentDetail) {
  try {
    const result: SingleResponse<AssessmentDetail> = yield call(
      AssessmentService.getDetails,
      assessmentId
    );
    yield put(actionCreators.getAssessmentDetailSuccess(result.data));
  } catch (e) {
    processError(e, (errorMessage) => {
      message.error(errorMessage);
    });
    yield put(actionCreators.getAssessmentDetailFailed());
  }
}

function* watchAssessmentDetails(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(
    actionTypes.GET_ASSESSMENT_DETAILS,
    getAssessmentDetailWorker
  );
}

export default watchAssessmentDetails;
