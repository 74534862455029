import "./AuditLogList.scss";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { DATATABLE_MAX_ROWS } from "@quest-finance/quest-fe-shared/dist/common/constants/datatable";
import { Dictionary } from "@quest-finance/quest-fe-shared/dist/common/types/Dictionary";
import { LabelValue } from "@quest-finance/quest-fe-shared/dist/common/types/LabelValue";
import { getOffset } from "@quest-finance/quest-fe-shared/dist/common/utils/dataTable";
import { dateFormat } from "@quest-finance/quest-fe-shared/dist/common/utils/date";
import {
  Button,
  Col,
  Form,
  message,
  Row,
  Select,
  Table,
  TableProps,
  Tooltip,
} from "antd";
import { ColumnsType } from "antd/es/table";
import * as lodash from "lodash";
import * as queryString from "query-string";
import ReactDiffViewer from "react-diff-viewer";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import {
  DATE_LIST_FORMAT,
  SHORT_DATE_FORMAT,
} from "../../../common/contstants/app";
import { processError } from "../../../common/utils/error";
import { workflowSelectors } from "../../../workflow";
import { AUDIT_MODELS } from "../../constants/auditModels";
import { assessmentResponseSelector } from "../../selectors/assessmentDetail";
import AuditLogsService from "../../services/AuditLogsService";
import {
  AuditLog,
  AuditLogParams,
  AuditLogRequest,
} from "../../types/AuditLog";

type AuditChangesProps = {
  oldValues: string | null;
  newValues: string | null;
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
};

const AuditChanges: React.FunctionComponent<AuditChangesProps> = ({
  oldValues,
  newValues,
  expanded,
  setExpanded,
}: AuditChangesProps) => {
  const toggleExpanded = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setExpanded(!expanded);
  };
  return (
    <div className="audit-changes">
      <Button className="qf-btn-green" onClick={toggleExpanded} size="small">
        {expanded ? "Hide value" : "Show value"}
      </Button>
      {expanded && (
        <div className="audit-changes-content">
          <ReactDiffViewer
            oldValue={JSON.stringify(oldValues, null, "\t")}
            newValue={JSON.stringify(newValues, null, "\t")}
            splitView={true}
          />
        </div>
      )}
    </div>
  );
};

const AuditLogList: React.FunctionComponent = () => {
  const assessment = useSelector(assessmentResponseSelector);
  const workflows = useSelector(workflowSelectors.workflowDetailsSelector);
  const [auditLogs, setAudtiLogs] = useState<AuditLog[]>([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const [isAllExpanded, setIsAllExpanded] = useState(false);
  const [expandedLogs, setExpandedLogs] = useState<number[]>([]);
  const [auditCategories, setAuditCategories] = useState<LabelValue[]>([]);
  const [categoryLabels, setCategoryLabels] = useState<Dictionary>();
  const [categoriesLoading, setCategoriesLoading] = useState(false);

  const urlQuery = useMemo<AuditLogParams>(() => {
    return queryString.parse(location.search, {
      parseNumbers: true,
      arrayFormat: "comma",
    });
  }, [location.search]);

  const [category, setCategory] = useState("");

  const modelNames = useMemo(() => {
    return [
      AUDIT_MODELS.APPLICATION,
      AUDIT_MODELS.ASSESSMENT,
      AUDIT_MODELS.FUNDER,
      AUDIT_MODELS.WORKFLOW,
    ];
  }, []);

  const modelIds = useMemo(() => {
    const ids = [
      assessment.externalId,
      assessment.id,
      ...workflows.map((workflow) => workflow.id),
    ];
    return ids.filter((id) => !!id);
  }, [assessment, workflows]);

  const getAuditCategories = useCallback(async () => {
    setCategoriesLoading(true);
    try {
      const result = await AuditLogsService.getAuditCategories();
      setAuditCategories(result.data);
    } catch (error) {
      processError(error, (errorMessage) => {
        message.error(errorMessage);
      });
    } finally {
      setCategoriesLoading(false);
    }
  }, []);

  const getAuditLogs = useCallback(async (payload: AuditLogRequest) => {
    setIsLoading(true);
    try {
      const result = await AuditLogsService.getAuditLogs(payload);
      setAudtiLogs(result.data);
      setTotal(result.count ?? 0);
    } catch (error) {
      processError(error, (errorMessage) => {
        message.error(errorMessage);
      });
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    getAuditCategories();
  }, [getAuditCategories]);

  useEffect(() => {
    if (auditCategories.length > 0) {
      const labels: Dictionary = {};
      auditCategories.forEach((category) => {
        labels[category.value] = category.label;
      });
      setCategoryLabels(labels);
    }
  }, [auditCategories]);

  useEffect(() => {
    const parsedQueryString = queryString.parse(location.search, {
      parseNumbers: true,
      arrayFormat: "comma",
    });

    const params: AuditLogParams = {
      limit: DATATABLE_MAX_ROWS,
      ...parsedQueryString,
    };

    const auditLogRequest = {
      ...params,
      modelId: modelIds.join(","),
      modelName: modelNames.join(","),
      offset: params.page
        ? getOffset(params.page, DATATABLE_MAX_ROWS)
        : undefined,
    };

    getAuditLogs(auditLogRequest);
    setIsAllExpanded(false);
    setExpandedLogs([]);
  }, [location, modelNames, modelIds, getAuditLogs]);

  const toggleAuditItem = (id: number, expanded: boolean) => {
    setExpandedLogs((previousState) => {
      if (expanded) {
        return [...previousState, id];
      }

      return previousState.filter((logId) => logId !== id);
    });
  };

  const toggleExpandedAll = (expand: boolean) => {
    setIsAllExpanded(expand);
    setExpandedLogs(() => (expand ? auditLogs.map(({ id }) => id) : []));
  };

  const columns: ColumnsType<AuditLog> = [
    {
      title: "Date",
      className: "date",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: false,
      width: "8%",
      render(_, { createdAt }) {
        const date = new Date(createdAt);
        return (
          <Tooltip title={dateFormat(date, DATE_LIST_FORMAT)}>
            {dateFormat(date, SHORT_DATE_FORMAT)}
          </Tooltip>
        );
      },
    },
    {
      title: "User",
      className: "user",
      dataIndex: "actor",
      key: "actor",
      sorter: false,
      width: "15%",
      render(_, { actor }) {
        return actor ? `${actor?.firstName} ${actor?.lastName}` : "-";
      },
    },
    {
      title: "Category",
      className: "category",
      dataIndex: "category",
      key: "category",
      width: "12%",
      render(_, { category }) {
        return category && categoryLabels ? categoryLabels[category] ?? "" : "";
      },
    },
    {
      title: "Changes",
      className: "changes",
      dataIndex: "changes",
      key: "category",
      render(_, { id, changesOld, changesNew, oldValues, newValues }) {
        return (
          <AuditChanges
            oldValues={changesOld || oldValues}
            newValues={changesNew || newValues}
            expanded={expandedLogs.includes(id)}
            setExpanded={(expanded) => toggleAuditItem(id, expanded)}
          />
        );
      },
    },
  ];

  const setParams = (params: AuditLogParams) => {
    const url = {
      ...urlQuery,
      ...params,
    };

    const query = queryString.stringify(url, { encode: false });

    history.push("?" + query);
  };

  const onTableChangeHandler: TableProps<AuditLog>["onChange"] = (
    pagination
  ) => {
    setParams({ page: pagination.current });
  };

  const handleCategoryChange = (value: string) => {
    setCategory(value);
    setParams({
      category: value,
      page: undefined,
    });
  };

  const handleClearCategory = () => {
    setCategory("");
    setParams({
      category: undefined,
      page: undefined,
    });
  };

  return (
    <div className="audit-log-list">
      <div className="section-header-1">Audit Logs</div>
      <Row>
        <Col span={24} md={6}>
          <Form layout="vertical">
            <Form.Item label="Filter by category">
              <Select
                disabled={categoriesLoading}
                value={category}
                onChange={handleCategoryChange}
                onClear={() => handleClearCategory()}
                allowClear
              >
                {auditCategories.map((category) => (
                  <Select.Option key={category.value}>
                    {category.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <Button
        className="qf-btn-green expand-button"
        onClick={() => toggleExpandedAll(!isAllExpanded)}
      >
        {isAllExpanded ? "Collapse all" : "Expand all"}
      </Button>
      <Table
        loading={isLoading}
        columns={columns}
        dataSource={auditLogs}
        onChange={onTableChangeHandler}
        pagination={{
          pageSize: DATATABLE_MAX_ROWS,
          simple: true,
          total: total,
          current: lodash.get(urlQuery, "page", 1),
        }}
        rowKey="id"
        size="small"
      />
    </div>
  );
};

export default AuditLogList;
