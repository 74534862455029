import APIService from "@quest-finance/quest-fe-shared/dist/common/services/APIService";
import { ListResponse } from "@quest-finance/quest-fe-shared/dist/common/types/ListResponse";
import { SingleResponse } from "@quest-finance/quest-fe-shared/dist/common/types/SingleResponse";
import * as queryString from "query-string";
import { WorkflowDocument } from "../types/Document";

class WorkflowDocumentService {
  public static async getList(
    workflowTaskId: number,
    limit?: number,
    offset?: number
  ): Promise<ListResponse<WorkflowDocument>> {
    const queryParams = queryString.stringify({ limit, offset });
    const result = await APIService.jsonRequest<
      ListResponse<WorkflowDocument>,
      unknown
    >(
      {
        method: "GET",
        path: `/workflow/task/${workflowTaskId}/documents?${queryParams}`,
      },
      true
    );
    return result;
  }

  public static async getListForWorkflow(
    workflowId: number,
    limit?: number,
    offset?: number
  ): Promise<ListResponse<WorkflowDocument>> {
    const queryParams = queryString.stringify({ limit, offset });
    const result = await APIService.jsonRequest<
      ListResponse<WorkflowDocument>,
      unknown
    >(
      {
        method: "GET",
        path: `/workflow/workflows/${workflowId}/documents?${queryParams}`,
      },
      true
    );
    return result;
  }

  public static async deleteDocument(
    workflowTaskId: number,
    documentId: string
  ): Promise<SingleResponse<boolean>> {
    const result = await APIService.jsonRequest<
      SingleResponse<boolean>,
      unknown
    >(
      {
        method: "DELETE",
        path: `/workflow/task/${workflowTaskId}/documents/${documentId}`,
      },
      true
    );
    return result;
  }
}

export default WorkflowDocumentService;
