import "./FunderSection.scss";
import React, { useCallback, useEffect, useState } from "react";
import { SaveOutlined, LoadingOutlined } from "@ant-design/icons";
import { dateFormat } from "@quest-finance/quest-fe-shared/dist/common/utils/date";
import { Button, Form, Input, message, Select } from "antd";
import { useParams } from "react-router-dom";
import { processError } from "../../../common/utils/error";
import {
  Funder,
  FunderRequest,
  FunderResponse,
  FunderService,
} from "../../../funder";

const FunderSection: React.FunctionComponent = () => {
  const { assessmentId } = useParams<{
    assessmentId: string;
  }>();
  const [loading, setLoading] = useState(false);
  const [funderRequest, setFunderRequest] = useState<FunderRequest>({
    funderId: 0,
    utilisationNumber: "",
  });
  const [funderAssessment, setFunderAssessment] = useState<FunderResponse>();
  const [funderList, setFunderList] = useState<Funder[]>([]);
  const [funderListLoading, setFunderListLoading] = useState(false);

  const getFunderDetails = useCallback(async () => {
    if (!assessmentId) return;
    try {
      setLoading(true);
      const { data } = await FunderService.get(assessmentId);
      if (data) {
        setFunderRequest({
          funderId: data.funderId,
          utilisationNumber: data.utilisationNumber,
        });
        setFunderAssessment(data);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      processError(error, (errorMessage) => {
        message.error(errorMessage);
      });
    }
  }, [assessmentId]);

  const getFunderList = useCallback(async () => {
    setFunderListLoading(true);
    try {
      const { data } = await FunderService.getFunderList();
      setFunderList(data);
    } catch (error) {
      processError(error, (errorMessage) => {
        message.error(errorMessage);
      });
    } finally {
      setFunderListLoading(false);
    }
  }, []);

  useEffect(() => {
    getFunderList();
  }, [getFunderList]);

  useEffect(() => {
    getFunderDetails();
  }, [getFunderDetails]);

  const handleChanges = (field: string, value: string) => {
    setFunderRequest((previousValue) => ({
      ...previousValue,
      [field]: value,
    }));
  };

  const handleFunderIdChange = (value: number) => {
    setFunderRequest((prevState) => ({
      ...prevState,
      funderId: value,
    }));
  };

  const handleSaveFunder = async () => {
    try {
      setLoading(true);
      await FunderService.save(assessmentId, funderRequest);
      getFunderDetails();
      message.success("Funder details successfully saved");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      processError(error, (errorMessage) => {
        message.error(errorMessage);
      });
    }
  };

  return (
    <div className="funder-section">
      <Form layout="inline">
        <Form.Item
          style={{
            minWidth: "205px",
          }}
          className="mb-3"
        >
          <label className="f-bold f-quest-navy">Funder Name</label>
          <Select
            onChange={(value) => handleFunderIdChange(value)}
            value={funderRequest.funderId}
            disabled={loading || funderListLoading}
          >
            <Select.Option value={0}>Select Funder</Select.Option>
            {funderList.map((funder) => (
              <Select.Option key={funder.id} value={funder.id}>
                {funder.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item className="d-flex align-items-end mb-3">
          <Input
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const { value } = event.target;
              handleChanges("utilisationNumber", value);
            }}
            value={funderRequest.utilisationNumber}
            disabled={loading || funderListLoading}
            placeholder="Utilisation number"
          />
        </Form.Item>
        <Form.Item className="d-flex align-items-end mb-3">
          <Button
            onClick={handleSaveFunder}
            disabled={funderListLoading || loading}
          >
            {loading ? <LoadingOutlined /> : <SaveOutlined />}
          </Button>
        </Form.Item>
      </Form>
      {funderAssessment && typeof funderAssessment.submittedBy !== "number" && (
        <div>
          {`Submitted ${
            funderAssessment.funder ? "to " + funderAssessment.funder.name : ""
          }  ${
            funderAssessment.submittedBy
              ? "by " +
                funderAssessment.submittedBy.firstName +
                " " +
                funderAssessment.submittedBy.lastName
              : ""
          }`}{" "}
          -{" "}
          {`${dateFormat(
            new Date(funderAssessment.submittedAt),
            "MMM d h:mm a"
          )}`}
        </div>
      )}
    </div>
  );
};

export default FunderSection;
