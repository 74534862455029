import APIService from "@quest-finance/quest-fe-shared/dist/common/services/APIService";
import { ListResponse } from "@quest-finance/quest-fe-shared/dist/common/types/ListResponse";
import { SingleResponse } from "@quest-finance/quest-fe-shared/dist/common/types/SingleResponse";
import { convertToQueryParams } from "@quest-finance/quest-fe-shared/dist/common/utils/object";
import { stringify } from "query-string";
import { TaskNoteAttributes } from "../types/TaskNote";
import {
  UpdateTaskOutcome,
  UpdateTaskState,
  WorkflowResponse,
  WorkflowTask,
  ListNoteRequest,
  WorkflowSummaryDetails,
  WorkflowGroupResponse,
} from "../types/Workflow";

class WorkflowService {
  public static async getDetails(
    targetId: string,
    state = ""
  ): Promise<ListResponse<WorkflowResponse>> {
    const queryParams = state ? `?state=${state}` : "";
    const result = await APIService.jsonRequest<
      ListResponse<WorkflowResponse>,
      unknown
    >(
      {
        method: "GET",
        path: `/workflow/workflows/target/${targetId}${queryParams}`,
      },
      true
    );
    return result;
  }

  public static async getWorkflowGroupTasks(
    targetId: string,
    code: string,
    groupCode?: string | string[],
    state?: string
  ): Promise<ListResponse<WorkflowGroupResponse>> {
    const groupCodeString = Array.isArray(groupCode)
      ? groupCode.join(",")
      : groupCode;
    const queryParams = convertToQueryParams({
      targetId,
      code,
      groupCode: groupCodeString,
      state,
    });
    const path = `/workflow/groups/group-tasks${queryParams}`;
    const result = await APIService.jsonRequest<
      ListResponse<WorkflowGroupResponse>,
      unknown
    >(
      {
        method: "GET",
        path,
      },
      true
    );
    return result;
  }

  public static async getWorkflowGroupSummary(
    targetId: string
  ): Promise<SingleResponse<WorkflowSummaryDetails>> {
    const result = await APIService.jsonRequest<
      SingleResponse<WorkflowSummaryDetails>,
      unknown
    >(
      {
        method: "GET",
        path: `/workflow/groups/summary?targetId=${targetId}`,
      },
      true
    );
    return result;
  }

  public static async updateTaskOutcome(
    workflowId: number,
    taskId: number,
    data: UpdateTaskOutcome
  ): Promise<SingleResponse<WorkflowTask>> {
    const result = await APIService.jsonRequest<
      SingleResponse<WorkflowTask>,
      UpdateTaskOutcome
    >(
      {
        method: "PUT",
        path: `/workflow/workflows/${workflowId}/tasks/${taskId}/outcome`,
        data,
      },
      true
    );
    return result;
  }

  public static async updateTaskState(
    workflowId: number,
    taskId: number,
    data: UpdateTaskState
  ): Promise<SingleResponse<WorkflowTask>> {
    const result = await APIService.jsonRequest<
      SingleResponse<WorkflowTask>,
      UpdateTaskState
    >(
      {
        method: "PUT",
        path: `/workflow/workflows/${workflowId}/tasks/${taskId}/state`,
        data,
      },
      true
    );
    return result;
  }

  public static async getNotes(
    workflowId: number,
    queryParams: ListNoteRequest
  ): Promise<ListResponse<TaskNoteAttributes>> {
    const params: string = stringify({
      ...queryParams,
    });
    const result = await APIService.jsonRequest<
      ListResponse<TaskNoteAttributes>,
      ListNoteRequest
    >(
      {
        method: "GET",
        path: `/workflow/workflow/${workflowId}/notes?${params}`,
      },
      true
    );

    return result;
  }

  public static async reRun(
    workflowId: number,
    targetId: string,
    groupIds: number[]
  ): Promise<ListResponse<WorkflowGroupResponse>> {
    const result = await APIService.jsonRequest<
      ListResponse<WorkflowGroupResponse>,
      { targetId: string; groupIds: number[] }
    >(
      {
        method: "POST",
        path: `/workflow/workflows/${workflowId}/groups/run`,
        data: { targetId, groupIds },
      },
      true
    );

    return result;
  }
}

export default WorkflowService;
