import React, { useState } from "react";
import { processErrorMessage } from "@quest-finance/quest-fe-shared/dist/error-handler/utils";
import { Button, Popconfirm, message } from "antd";
import LMSService from "../../services/LMSService";

type SubmitApplicationBtnProps = {
  assessmentId: string;
};

const notifKey = "push-to-lms-notif";

const SubmitApplicationBtn: React.FC<SubmitApplicationBtnProps> = (
  props: SubmitApplicationBtnProps
) => {
  const [loading, setLoading] = useState(false);
  const onConfirmHandler = async () => {
    setLoading(true);
    message.destroy(notifKey);

    try {
      await LMSService.submitToLms({ assessmentId: props.assessmentId });
      message.success("Application successfully sent to LMS");
    } catch (e) {
      const content = processErrorMessage(e);
      message.error({
        content,
        key: notifKey,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Popconfirm
      title="Submit this application to LMS?"
      okText="Submit"
      cancelText="Cancel"
      onConfirm={() => onConfirmHandler()}
      arrowPointAtCenter
    >
      <Button className="qf-btn-green" loading={loading}>
        Push application to LMS
      </Button>
    </Popconfirm>
  );
};

export default SubmitApplicationBtn;
