import "./Navigation.scss";
import util from "util";
import React, { useEffect, useMemo } from "react";
import { CheckCircleFilled } from "@ant-design/icons";
import { LOADING_STATUS } from "@quest-finance/quest-fe-shared/dist/common/constants/loadingStatuses";
import { message } from "antd";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { processError } from "../../../common/utils/error";
import {
  useWorkflowDispatch,
  workflowSelectors,
  workflowActions,
} from "../../../workflow";
import { NAV_ITEMS } from "../../constants/navigation";
import { creditAssessment } from "../../constants/routes";
import { Slug } from "../../types/Navigation";

const { workflowSelector } = workflowSelectors;
const { getWorkflowSummary } = workflowActions;

type NavigationProps = {
  targetId?: string;
};

const Navigation: React.FunctionComponent<NavigationProps> = ({
  targetId,
}: NavigationProps) => {
  const workflowDispatch = useWorkflowDispatch();
  const {
    workflowSummaryDetails: { status, error },
  } = useSelector(workflowSelector);

  useEffect(() => {
    if (status === LOADING_STATUS.FAILED) {
      processError(error, (errorMessage) => {
        message.error(errorMessage);
      });
    }
  }, [status, error]);

  useEffect(() => {
    if (targetId) {
      workflowDispatch(getWorkflowSummary(targetId));
    }
  }, [workflowDispatch, targetId]);

  const urlParams = useParams<{ section: string; assessmentId: string }>();
  const workflowSummary = useSelector(
    workflowSelectors.workflowSummaryDetailsSelector
  );
  const applicantGuarantorsState = useMemo(() => {
    if (workflowSummary) {
      return (
        workflowSummary.APPLICANT?.state === "DONE" &&
          workflowSummary.GUARANTOR?.state === "DONE" &&
          workflowSummary.APPLICANT_EQUIFAX?.state === "DONE",
        workflowSummary.GUARANTOR_SME_EQUIFAX?.state === "DONE" &&
          workflowSummary.GUARANTOR_INDIVIDUAL_EQUIFAX?.state === "DONE" &&
          workflowSummary.GUARANTOR_ILLION?.state === "DONE" &&
          workflowSummary.GUARANTOR_FRANKIEONE?.state === "DONE"
      );
    }

    return false;
  }, [workflowSummary]);

  const navWorkflowGroups: Record<string, boolean> = {
    [Slug.QUOTE]: workflowSummary?.QUOTE?.state === "DONE",
    [Slug.APPLICANT_GUARANTORS]: applicantGuarantorsState,
    [Slug.SECURITY]: workflowSummary?.SECURITY?.state === "DONE",
    [Slug.SETTLEMENT]: workflowSummary?.SETTLEMENT?.state === "DONE",
  };

  const navItems = Object.keys(NAV_ITEMS).map((slug) => {
    const { label, Icon } = NAV_ITEMS[slug as Slug];
    const active = slug === urlParams.section ? "active" : "";
    const complete = navWorkflowGroups[slug];
    const url = util.format(creditAssessment, urlParams.assessmentId, slug);

    return (
      <Link key={slug} to={url} className={classNames({ active, complete })}>
        {complete ? <CheckCircleFilled /> : <Icon />}
        {label}
      </Link>
    );
  });

  return (
    <div className="ce-navigation">
      <div className="inner">{navItems}</div>
    </div>
  );
};

export default Navigation;
