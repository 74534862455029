import "./SettlementApproval.scss";
import React, { useState } from "react";
import { LOADING_STATUS } from "@quest-finance/quest-fe-shared/dist/common/constants/loadingStatuses";
import { Button, Empty, message, Spin } from "antd";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { processError } from "../../../common/utils/error";
import { getAssessmentDetail } from "../../actions/creators/assessmentDetail";
import { STATUS_LABELS } from "../../constants/approvalCondition";
import { useAssessmentDetailsDispatch } from "../../dispatchers";
import {
  assessmentResponseSelector,
  assessmentResponseStatusSelector,
} from "../../selectors/assessmentDetail";
import ApprovalConditionService from "../../services/ApprovalCondition";
import { Status, ApprovalCondition } from "../../types/ApprovalCondition";

type ActionButtonsProps = {
  condition: ApprovalCondition;
  isLoading: boolean;
  status: Status;
  onChangeStatus: (condition: ApprovalCondition, status: Status) => void;
};

const ActionButtons: React.FunctionComponent<ActionButtonsProps> = ({
  condition,
  isLoading,
  status,
  onChangeStatus,
}: ActionButtonsProps) => {
  return (
    <Spin spinning={isLoading}>
      {Object.keys(STATUS_LABELS).map((key) => {
        const statusKey = key as Status;
        const onClickHandler = () => {
          if (status !== statusKey) {
            onChangeStatus(condition, statusKey);
          }
        };

        return (
          <Button
            key={key}
            className={classNames("quest-button-square", {
              selected: status === statusKey,
            })}
            onClick={onClickHandler}
          >
            {STATUS_LABELS[statusKey]}
          </Button>
        );
      })}
    </Spin>
  );
};

const SettlementApproval: React.FunctionComponent = () => {
  const assessmentDispatch = useAssessmentDetailsDispatch();
  const [filter, setFilter] = useState<string>("");
  const { id: assessmentId, approvalConditions } = useSelector(
    assessmentResponseSelector
  );
  const [itemsOnUpdate, setItemsOnUpdate] = useState<string[]>([]);
  const status = useSelector(assessmentResponseStatusSelector);
  const conditions = filter
    ? approvalConditions.filter((condition) => condition.status === filter)
    : approvalConditions;

  const handleFilterChange = (value: string) => {
    assessmentDispatch(getAssessmentDetail(assessmentId));
    setFilter(value);
  };

  const onChangeStatusHandler = async (
    { id, condition }: ApprovalCondition,
    status: Status
  ) => {
    message.destroy(id);
    setItemsOnUpdate((state) => [id, ...state]);
    const partialText = condition.split(" ").splice(0, 5).join(" ");

    try {
      await ApprovalConditionService.updateStatus(assessmentId, id, status);

      message.success({
        key: id,
        content: `Status of "${partialText}..." has been updated. `,
      });
      assessmentDispatch(getAssessmentDetail(assessmentId));
    } catch (e) {
      processError(e, () => {
        message.error({
          key: id,
          content: `Failed to update status of "${partialText}...".`,
        });
      });
    } finally {
      setItemsOnUpdate((state) =>
        state.filter((conditionId) => id !== conditionId)
      );
    }
  };

  return (
    <div className="settlement-approval">
      <div className="section-header-1">Settlement Approval</div>
      <Spin spinning={status === LOADING_STATUS.LOADING}>
        <table className="conditions-list-table mb-3">
          <thead>
            <tr>
              <th style={{ width: "5%" }}>Pass</th>
              <th style={{ width: "80%" }}>Description</th>
              <th style={{ width: "15%" }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {conditions.length === 0 && (
              <tr>
                <td colSpan={3}>
                  <Empty description="No conditions found" />
                </td>
              </tr>
            )}
            {conditions.map((cond) => {
              const { id, status, condition } = cond;
              return (
                <tr key={id}>
                  <td className="text-center">
                    <label
                      className={classNames("ant-checkbox-wrapper", {
                        "ant-checkbox-wrapper-checked": status === Status.PASS,
                      })}
                    >
                      <span
                        className={classNames("ant-checkbox", {
                          "ant-checkbox-checked": status === Status.PASS,
                        })}
                      >
                        <input
                          type="checkbox"
                          className="ant-checkbox-input"
                          value="pass"
                          checked={status === Status.PASS}
                          readOnly
                        />
                        <span className="ant-checkbox-inner"></span>
                      </span>
                    </label>
                  </td>
                  <td>{condition}</td>
                  <td className="action-buttons">
                    <ActionButtons
                      condition={cond}
                      status={status}
                      isLoading={itemsOnUpdate.includes(id)}
                      onChangeStatus={onChangeStatusHandler}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Spin>
      <div className="button-group">
        <Button
          className={classNames("qf-btn-green mr-3", {
            active: filter === Status.REFER,
          })}
          onClick={() => handleFilterChange(Status.REFER)}
        >
          View refer
        </Button>
        <Button className="qf-btn-green" onClick={() => handleFilterChange("")}>
          View all
        </Button>
      </div>
    </div>
  );
};

export default SettlementApproval;
