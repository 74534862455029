import "./IntroducerDetails.scss";
import React, { useCallback, useEffect, useState } from "react";
import {
  ApplicationResponse,
  getApplicationSelector,
} from "@quest-finance/quest-fe-shared/dist/application";
import { Dictionary } from "@quest-finance/quest-fe-shared/dist/common/types/Dictionary";
import { message, Spin } from "antd";
import lodash from "lodash";
import { useSelector } from "react-redux";
import { processError } from "../../../common/utils/error";
import AssessmentService from "../../services/AssessmentService";

const IntroducerDetails: React.FC = () => {
  const application: ApplicationResponse = useSelector(getApplicationSelector);
  const [assessmentTotal, setAssessmentTotal] = useState<Dictionary>({});
  const [isFetchingTotals, setIsFetchingTotals] = useState(false);
  const getAssessmentCount = useCallback(async () => {
    setIsFetchingTotals(true);

    if (application.user) {
      try {
        const { data } = await AssessmentService.getIntroducerAssessmentTotal(
          application.user.id
        );
        setAssessmentTotal(data);
      } catch (error) {
        processError(error, (errorMessage) => {
          message.error(errorMessage);
        });
      } finally {
        setIsFetchingTotals(false);
      }
    }
  }, [application]);

  useEffect(() => {
    getAssessmentCount();
  }, [getAssessmentCount]);

  return lodash.isEmpty(assessmentTotal) ? null : (
    <Spin spinning={isFetchingTotals}>
      <table className="introducer-details">
        <tbody>
          <tr>
            <td>App(s) submitted :</td>
            <td>{assessmentTotal.submitted}</td>
          </tr>
          <tr>
            <td>App(s) In settlement/Settled :</td>
            <td>{assessmentTotal.settled}</td>
          </tr>
        </tbody>
      </table>
    </Spin>
  );
};

export default IntroducerDetails;
