import { SingleResponse } from "@quest-finance/quest-fe-shared/dist/common/types/SingleResponse";
import { call, ForkEffect, put, takeLatest } from "redux-saga/effects";
import * as actionCreators from "../actions/creators/assessmentExtras";
import * as actionTypes from "../actions/types/assessmentExtras";
import AssessmentExtrasService from "../services/AssessmentExtrasService";
import { GetAssessmentExtrasResponse } from "../types/AssessmentExtras";

function* getAssessmentExtrasWorker({
  assessmentId,
  data,
}: actionTypes.GetAssessmentExtras) {
  try {
    const response: SingleResponse<GetAssessmentExtrasResponse> = yield call(
      AssessmentExtrasService.getData,
      assessmentId,
      data
    );
    yield put(actionCreators.getAssessmentExtrasSuccess(response.data));
  } catch (error) {
    yield put(actionCreators.getAssessmentExtrasFailed(error));
  }
}

function* watchAssessmentExtras(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(
    actionTypes.CE_GET_ASSESSMENT_EXTRAS,
    getAssessmentExtrasWorker
  );
}

export default watchAssessmentExtras;
