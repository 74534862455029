import { ListResponse } from "@quest-finance/quest-fe-shared/dist/common/types/ListResponse";
import { message } from "antd";
import { put, call, ForkEffect, takeLatest } from "redux-saga/effects";
import { processError } from "../../common/utils/error";
import * as actionCreators from "../actions/creators/noteList";
import * as actionTypes from "../actions/types/noteList";
import NoteService from "../services/NoteService";
import { NoteDocument } from "../types/Notes";

function* getNotesListWorker({
  assessmentId,
  queryParams,
}: actionTypes.GetAssessmentNotes) {
  try {
    const result: ListResponse<NoteDocument> = yield call(
      NoteService.getList,
      assessmentId,
      queryParams
    );
    yield put(actionCreators.getAssessmentNotesSuccess(result));
  } catch (error) {
    yield put(actionCreators.getAssessmentNotesFailed(error));
    processError(error, (errorMessage) => {
      message.error(errorMessage);
    });
  } finally {
    yield put(actionCreators.getAssessmentNotesCompleted());
  }
}

function* watchAdminData(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(actionTypes.GET_ASSESSMENT_NOTES, getNotesListWorker);
}

export default watchAdminData;
