import { ListResponse } from "@quest-finance/quest-fe-shared/dist/common/types/ListResponse";
import { TaskNoteAttributes, TaskNoteListRequest } from "../../types/TaskNote";
import * as actionType from "../types/taskNote";

export const getTaskNotes = (
  taskId: number,
  payload?: TaskNoteListRequest
): actionType.GetTaskNotes => ({
  type: actionType.GET_TASK_NOTES,
  taskId,
  payload,
});

export const getTaskNotesSuccess = (
  response: ListResponse<TaskNoteAttributes>
): actionType.GetTaskNotesSuccess => ({
  type: actionType.GET_TASK_NOTES_SUCCESS,
  taskNotes: response.data,
  count: response.count ?? 0,
});

export const getTaskNotesFailed = (): actionType.GetTaskNotesFailed => ({
  type: actionType.GET_TASK_NOTES_FAILED,
});

export const getTaskNotesCompleted = (): actionType.GetTaskNotesCompleted => ({
  type: actionType.GET_TASK_NOTES_COMPLETED,
});

export const resetTaskNote = (): actionType.ResetTaskNote => ({
  type: actionType.RESET_TASK_NOTE,
});
export const updateTaskNote = (
  note: TaskNoteAttributes
): actionType.UpdateTaskNote => ({
  type: actionType.UPDATE_TASK_NOTE,
  note,
});
