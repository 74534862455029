import APIService from "@quest-finance/quest-fe-shared/dist/common/services/APIService";
import { ListResponse } from "@quest-finance/quest-fe-shared/dist/common/types/ListResponse";
import { SingleResponse } from "@quest-finance/quest-fe-shared/dist/common/types/SingleResponse";
import { Funder, FunderRequest, FunderResponse } from "../types/Funder";
import { GenerateCSVRequest } from "../types/GenerateCSVRequest";

class FunderService {
  public static async generateAndDownloadCsv(
    data: GenerateCSVRequest
  ): Promise<SingleResponse<string>> {
    const result = await APIService.jsonRequest<
      SingleResponse<string>,
      GenerateCSVRequest
    >(
      {
        method: "POST",
        path: `/funder/funders/assessment/generate-csv`,
        data,
      },
      true
    );
    return result;
  }

  public static async get(
    assessmentId: string
  ): Promise<SingleResponse<FunderResponse>> {
    const result = await APIService.jsonRequest<
      SingleResponse<FunderResponse>,
      unknown
    >(
      {
        method: "GET",
        path: `/funder/funders/assessment/${assessmentId}`,
      },
      true
    );
    return result;
  }

  public static async save(
    assessmentId: string,
    data: FunderRequest
  ): Promise<SingleResponse<FunderResponse>> {
    const result = await APIService.jsonRequest<
      SingleResponse<FunderResponse>,
      FunderRequest
    >(
      {
        method: "PUT",
        path: `/funder/funders/assessment/${assessmentId}`,
        data,
      },
      true
    );
    return result;
  }

  public static async getFunderList(): Promise<ListResponse<Funder>> {
    const result = await APIService.jsonRequest<ListResponse<Funder>, unknown>(
      {
        method: "GET",
        path: `/funder/funders`,
      },
      true
    );
    return result;
  }
}

export default FunderService;
