import { Dictionary } from "@quest-finance/quest-fe-shared/dist/common/types/Dictionary";

export enum IAM_STATUSES {
  ACTIVE = "ACTIVE",
  SUSPENDED = "SUSPENDED",
}

export const IAM_STATUS_LABEL: Dictionary = {
  [IAM_STATUSES.ACTIVE]: "Active",
  [IAM_STATUSES.SUSPENDED]: "Suspended",
};
