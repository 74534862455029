import "./AssessmentDocuments.scss";
import React, { useCallback, useEffect, useState } from "react";
import { DeleteFilled } from "@ant-design/icons";
import { DATATABLE_MAX_ROWS } from "@quest-finance/quest-fe-shared/dist/common/constants/datatable";
import { LOADING_STATUS } from "@quest-finance/quest-fe-shared/dist/common/constants/loadingStatuses";
import { getOffset } from "@quest-finance/quest-fe-shared/dist/common/utils/dataTable";
import { dateFormat } from "@quest-finance/quest-fe-shared/dist/common/utils/date";
import {
  useDownloadDocument,
  DocumentService,
} from "@quest-finance/quest-fe-shared/dist/files";
import { Pagination, Empty, message, Popconfirm, Spin } from "antd";

import lodash from "lodash";
import { useSelector } from "react-redux";
import { DATE_LIST_FORMAT } from "../../../common/contstants/app";
import { processError } from "../../../common/utils/error";
import { getAssessmentDocuments } from "../../actions/creators/documentList";
import { useDocumentListDispatch } from "../../dispatchers";
import {
  documentsSelector,
  documentsStatusSelector,
  documentsCountSelector,
} from "../../selectors/documentList";
import {
  DocumentPurpose,
  AssessmentDocument,
} from "../../types/AssessmentDocument";

type DocumentListProps = {
  assessmentId: string;
  lastUpload: number;
};

const internalDocumentPurpose = [
  DocumentPurpose.CREDIT_REPORT,
  DocumentPurpose.INTERNAL,
  DocumentPurpose.KYC_REPORT,
];
const AssessmentDocuments: React.FunctionComponent<DocumentListProps> = ({
  assessmentId,
  lastUpload,
}: DocumentListProps) => {
  const loadingStatus = useSelector(documentsStatusSelector);
  const documentCount = useSelector(documentsCountSelector);
  const documents = useSelector(documentsSelector);
  const [page, setPage] = useState(1);
  const { downloadFile } = useDownloadDocument();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const documentListDispatch = useDocumentListDispatch();

  const onPageChange = (page: number) => {
    getDocuments(page);
  };

  const getDocuments = useCallback(
    (page = 1) => {
      setPage(page);
      documentListDispatch(
        getAssessmentDocuments(
          assessmentId,
          internalDocumentPurpose,
          DATATABLE_MAX_ROWS,
          getOffset(page, DATATABLE_MAX_ROWS)
        )
      );
    },
    [assessmentId, setPage, documentListDispatch]
  );

  const handleFileDownload = (
    event: React.MouseEvent<HTMLAnchorElement>,
    { id }: AssessmentDocument
  ) => {
    event.preventDefault();
    downloadFile(
      `/assessment/assessments/${assessmentId}/documents/${id}/download`
    );
  };

  const handleFileDelete = async ({ id }: AssessmentDocument) => {
    setDeleteLoading(true);

    try {
      await DocumentService.deleteDocument(
        `/assessment/assessments/${assessmentId}/documents/${id}`
      );
      getDocuments();

      message.success("Internal document has been deleted.");
    } catch (e) {
      processError(e, (errorMessage) => {
        message.error(errorMessage);
      });
    } finally {
      setDeleteLoading(false);
    }
  };

  const renderFileNameColumn = (document: AssessmentDocument) => {
    return (
      <a
        href="/"
        onClick={(event: React.MouseEvent<HTMLAnchorElement>) =>
          handleFileDownload(event, document)
        }
      >
        {document.originalFilename}
      </a>
    );
  };

  const renderDeleteButton = (doc: AssessmentDocument) => {
    return (
      <Popconfirm
        title="Sure to delete?"
        onConfirm={() => handleFileDelete(doc)}
        placement="left"
      >
        <DeleteFilled className="delete-button" />
      </Popconfirm>
    );
  };

  useEffect(() => {
    if (assessmentId) {
      getDocuments();
    }
  }, [assessmentId, lastUpload, getDocuments]);

  return (
    <div className="document-list">
      <Spin
        spinning={deleteLoading || loadingStatus === LOADING_STATUS.LOADING}
      >
        <table className="document-table">
          <tbody>
            {documents.length === 0 && (
              <tr>
                <td colSpan={3}>
                  <Empty description="No documents found" />
                </td>
              </tr>
            )}
            {documents.map((document) => (
              <tr key={document.id}>
                <td>
                  {dateFormat(new Date(document.createdAt), DATE_LIST_FORMAT)}
                </td>
                <td>
                  {lodash.isEmpty(document.createdBy)
                    ? "-"
                    : `${document.createdBy.firstName} ${document.createdBy.lastName}`}
                </td>
                <td>
                  {renderFileNameColumn(document)}
                  {renderDeleteButton(document)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination
          className="list-pagination"
          current={page}
          onChange={onPageChange}
          pageSize={DATATABLE_MAX_ROWS}
          total={documentCount}
          hideOnSinglePage
          simple
        />
      </Spin>
    </div>
  );
};

export default AssessmentDocuments;
