import "./SupportingDocuments.scss";
import React, { useEffect, useState } from "react";
import { documentPurposes } from "@quest-finance/quest-fe-shared/dist/application";
import { Collapse, Tabs } from "antd";
import { useSelector } from "react-redux";
import { ApplicationDocuments } from "../../../application";
import { Uploader } from "../../../document";
import { QfCollapse } from "../../../theme";
import {
  DocumentList as WorkflowDocument,
  workflowSelectors,
} from "../../../workflow";
import { assessmentResponseSelector } from "../../selectors/assessmentDetail";
import { DocumentList } from "../../types/Document";
import AssessmentDocuments from "../assessment-documents/AssessmentDocuments";

const { Panel } = Collapse;
const { TabPane } = Tabs;

type SupportingDocumentsProps = {
  defaultAtiveTab?: DocumentList;
};
const SupportingDocuments: React.FunctionComponent<SupportingDocumentsProps> = ({
  defaultAtiveTab = DocumentList.INTERNAL,
}: SupportingDocumentsProps) => {
  const [lastUpload, setLastUpload] = useState(0);
  const workflow = useSelector(workflowSelectors.workflowDetailsSelector);
  const assessment = useSelector(assessmentResponseSelector);
  const [activeTab, setActiveTab] = useState<DocumentList>(defaultAtiveTab);
  const [uploadPath, setUploadPath] = useState(
    `/assessment/assessments/${assessment.id}/documents`
  );
  const [documentPurpose, setDocumentPurpose] = useState<
    documentPurposes.DOCUMENT_PURPOSES
  >(documentPurposes.DOCUMENT_PURPOSES.INTERNAL_DOCUMENT);

  useEffect(() => {
    let docPurpose = "";
    let uploadPath = `/application/applications/${assessment.externalId}/documents/upload`;

    if (activeTab === DocumentList.INTERNAL) {
      docPurpose = documentPurposes.DOCUMENT_PURPOSES.INTERNAL_DOCUMENT;
      uploadPath = `/assessment/assessments/${assessment.id}/documents`;
    } else if (activeTab === DocumentList.SUPPORTING) {
      docPurpose = documentPurposes.DOCUMENT_PURPOSES.SUPPORTING_DOCUMENT;
    } else if (activeTab === DocumentList.SETTLEMENT) {
      docPurpose = documentPurposes.DOCUMENT_PURPOSES.SETTLEMENT_DOCUMENT;
    }

    setDocumentPurpose(docPurpose as documentPurposes.DOCUMENT_PURPOSES);
    setUploadPath(uploadPath);
  }, [assessment, activeTab]);

  const onTabChange = (key: string) => {
    setActiveTab(key as DocumentList);
  };

  const onUploadComplete = (numOfFileUploaded: number) => {
    if (numOfFileUploaded) setLastUpload(Date.now());
  };

  return (
    <QfCollapse
      defaultActiveKey={["1"]}
      className="supporting-documents"
      expandIconPosition="right"
      ghost
    >
      <Panel
        key="1"
        header={<div className="section-header-1">Supporting Documents</div>}
      >
        <Uploader
          uploadPath={uploadPath}
          documentPurpose={documentPurpose}
          onUploadComplete={onUploadComplete}
          disabled={activeTab !== DocumentList.INTERNAL}
        />
        <Tabs
          activeKey={activeTab}
          onChange={onTabChange}
          className="documents-tabs"
          destroyInactiveTabPane
        >
          <TabPane tab="Internal" key={DocumentList.INTERNAL}>
            <AssessmentDocuments
              assessmentId={assessment.id}
              lastUpload={lastUpload}
            />
          </TabPane>
          <TabPane tab="Supporting" key={DocumentList.SUPPORTING}>
            <ApplicationDocuments
              applicationId={assessment.externalId}
              purpose={documentPurposes.DOCUMENT_PURPOSES.SUPPORTING_DOCUMENT}
              lastUpload={lastUpload}
            />
          </TabPane>
          <TabPane tab="Settlement" key={DocumentList.SETTLEMENT}>
            <ApplicationDocuments
              applicationId={assessment.externalId}
              purpose={documentPurposes.DOCUMENT_PURPOSES.SETTLEMENT_DOCUMENT}
              lastUpload={lastUpload}
            />
          </TabPane>
          {workflow.length > 0 && (
            <TabPane tab="Workflow" key={DocumentList.WORKFLOW}>
              <WorkflowDocument workflowId={workflow[0].id} />
            </TabPane>
          )}
        </Tabs>
      </Panel>
    </QfCollapse>
  );
};

export default SupportingDocuments;
