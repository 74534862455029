import React, { useCallback, useState } from "react";
import { LabelValue } from "@quest-finance/quest-fe-shared/dist/common/types/LabelValue";
import { message, Select, Spin } from "antd";
import { SelectProps } from "antd/es/select";
import { UserService } from "../../iam";
import { processError } from "../utils/error";

interface UserDropdownProps
  extends Omit<SelectProps<LabelValue<number>>, "options | children"> {
  clientIds?: number;
  isIntroducer?: boolean;
}
const UserDropdown: React.FunctionComponent<UserDropdownProps> = ({
  clientIds,
  isIntroducer,
  ...props
}: UserDropdownProps) => {
  const [options, setOptions] = useState<LabelValue<number>[]>([]);
  const [fetching, setFetching] = useState(false);

  const loadUsers = useCallback(
    async (inputValue?: string): Promise<LabelValue<number>[]> => {
      setFetching(true);
      try {
        const result = await UserService.getUserList({
          offset: 0,
          limit: 10,
          order: "firstName",
          keywords: inputValue,
          clientIds,
          isIntroducer,
        });

        setFetching(false);
        setOptions(
          result.data.map((user) => ({
            label: `${user.firstName} ${user.lastName}`,
            value: user.id,
          }))
        );
      } catch (error) {
        setFetching(false);
        processError(error, (errorMessage) => {
          message.error(errorMessage);
        });
      }

      return [];
    },
    [clientIds, isIntroducer]
  );

  return (
    <Select<LabelValue<number>>
      labelInValue
      filterOption={false}
      onSearch={loadUsers}
      onFocus={() => loadUsers()}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={options}
      showSearch
    />
  );
};

export default UserDropdown;
