import { IAM_STATUSES } from "../constants/iamStatuses";

export interface ClientForm {
  name: string;
  isIntroducer: boolean | null;
  pricing: string;
  referer: string;
  bdmId: number | null;
  status: string;
}

export const clientFormDefaultValue: ClientForm = {
  name: "",
  isIntroducer: true,
  pricing: "",
  referer: "",
  bdmId: null,
  status: IAM_STATUSES.ACTIVE,
};
