import { applicationStatuses } from "@quest-finance/quest-fe-shared/dist/application";

const { APPLICATION_STATUSES } = applicationStatuses;

export const REASON_TRIGGER_STATUSES = [
  APPLICATION_STATUSES.APPROVED_WAITING_FOR_MORE_INFO,
  APPLICATION_STATUSES.DECLINED,
  APPLICATION_STATUSES.WITHDRAWN_BY_INTRODUCER,
  APPLICATION_STATUSES.WITHDRAWN_BY_LENDER,
] as string[];

export const STATUS_GROUP_OTHER = "OTHER";
