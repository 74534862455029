import React from "react";
import { Collapse } from "antd";
import { QfCollapse } from "../../../theme";
import FunderSection from "./FunderSection";
import SettlementApproval from "./SettlementApproval";

const { Panel } = Collapse;

const InternalProcessing: React.FunctionComponent = () => {
  return (
    <QfCollapse defaultActiveKey={["1"]} expandIconPosition="right" ghost>
      <Panel
        key="1"
        header={<div className="section-header-1">Internal Processing</div>}
      >
        <FunderSection />
        <SettlementApproval />
      </Panel>
    </QfCollapse>
  );
};

export default InternalProcessing;
