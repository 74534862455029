import "./DocumentList.scss";
import React, { useCallback } from "react";
import { DeleteFilled } from "@ant-design/icons";
import { dateFormat } from "@quest-finance/quest-fe-shared/dist/common/utils/date";
import { Button, Empty, Popconfirm, Spin } from "antd";
import cs from "classnames";
import { DATE_LIST_FORMAT } from "../../../common/contstants/app";
import { WorkflowDocument } from "../../types/Document";

type DocumentListProps = {
  documents: WorkflowDocument[];
  onDelete: (documentId: string) => void;
  onDownload: (documentId: string) => void;
  isLoading?: boolean;
  readOnly: boolean;
};

const DocumentList: React.FunctionComponent<DocumentListProps> = ({
  documents,
  onDelete,
  onDownload,
  isLoading,
  readOnly = false,
}: DocumentListProps) => {
  const handleDownload = useCallback(
    (documentId: string) => {
      onDownload(documentId);
    },
    [onDownload]
  );

  const handleDeleteDocument = useCallback(
    (documentId: string) => {
      if (!readOnly) onDelete(documentId);
    },
    [onDelete, readOnly]
  );

  const renderDeleteButton = (documentId: string) => {
    return (
      <Popconfirm
        title="Sure to delete this document?"
        onConfirm={() => handleDeleteDocument(documentId)}
      >
        <Button className="delete-button">
          <DeleteFilled />
        </Button>
      </Popconfirm>
    );
  };

  return (
    <Spin spinning={isLoading}>
      <table
        className={cs("workflow-document-list", { "read-only": readOnly })}
      >
        <thead>
          <tr>
            <th style={{ width: "60%" }}>Document name</th>
            <th style={{ width: "35%" }}>Time upload</th>
            <th className="delete" style={{ width: "5%" }}></th>
          </tr>
        </thead>
        <tbody>
          {documents.length === 0 && (
            <tr>
              <td colSpan={3}>
                <Empty description="No documents found" />
              </td>
            </tr>
          )}
          {documents.map((document) => (
            <tr key={document.id}>
              <td>
                {dateFormat(new Date(document.createdAt), DATE_LIST_FORMAT)}
              </td>
              <td>
                <Button
                  onClick={() => handleDownload(document.id)}
                  className="btn-download"
                >
                  {document.filename}
                </Button>
              </td>
              <td className="text-center delete">
                {renderDeleteButton(document.id)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Spin>
  );
};

export default DocumentList;
