import React, { useCallback, useEffect, useState } from "react";
import { applicationStatuses } from "@quest-finance/quest-fe-shared/dist/application";
import { DATATABLE_MAX_ROWS } from "@quest-finance/quest-fe-shared/dist/common/constants/datatable";
import { useSelector } from "react-redux";
import { assessmentStatusSelector } from "../../../assessment-v2/selectors/assessmentDetail";
import { getTaskNotes, resetTaskNote } from "../../actions/creators/taskNote";
import { closedStatus } from "../../constants/workflow";
import { useTaskNoteDispatch } from "../../dispatchers";
import { TaskNoteAttributes } from "../../types/TaskNote";
import TaskNoteList from "./TaskNoteList";
import TaskNotesForm from "./TaskNotesForm";

type WorkflowTaskNoteProps = {
  workflowTaskId: number;
};

const WorkflowTaskNote: React.FunctionComponent<WorkflowTaskNoteProps> = ({
  workflowTaskId,
}: WorkflowTaskNoteProps) => {
  const assessmentStatus = useSelector(
    assessmentStatusSelector
  ) as applicationStatuses.APPLICATION_STATUSES;
  const [page, setPage] = useState(1);
  const [note, setNote] = useState<TaskNoteAttributes>();
  const taskNoteDispatch = useTaskNoteDispatch();
  const isWorfkflowDisabled = closedStatus.includes(assessmentStatus);

  const onNoteSubmit = () => {
    if (note) {
      setNote(undefined);
    } else {
      if (page === 1) {
        taskNoteDispatch(
          getTaskNotes(workflowTaskId, {
            limit: DATATABLE_MAX_ROWS,
          })
        );
      } else {
        setPage(1);
      }
    }
  };

  useEffect(() => {
    return () => {
      taskNoteDispatch(resetTaskNote());
    };
  }, [taskNoteDispatch]);

  const handleOnUpdate = useCallback((note: TaskNoteAttributes) => {
    setNote(note);
  }, []);

  const handleOnCancel = useCallback(() => {
    setNote(undefined);
  }, []);

  const onPageChange = (page: number) => {
    setPage(page);
    setNote(undefined);
  };

  return (
    <div className="workflow-task-notes">
      <TaskNotesForm
        workflowTaskId={workflowTaskId}
        note={note}
        onSave={onNoteSubmit}
        onCancel={handleOnCancel}
        disabled={isWorfkflowDisabled}
      />
      <TaskNoteList
        workflowTaskId={workflowTaskId}
        page={page}
        onPageChange={onPageChange}
        onNoteUpdate={handleOnUpdate}
        readOnly={isWorfkflowDisabled}
      />
    </div>
  );
};

export default WorkflowTaskNote;
