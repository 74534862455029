import { shouldReportToSentry } from "@quest-finance/quest-fe-shared/dist/error-handler";
import { AxiosError } from "axios";
import {
  UPDATE_STATUS_RESTRICTION_ERROR,
  UPDATE_STATUS_SAME_STATUS_ERROR,
} from "../constants/expectedErrors";

export const ignoreUpdateStatusError = (error: AxiosError): boolean => {
  if (error.isAxiosError) {
    return shouldReportToSentry(error, [
      UPDATE_STATUS_RESTRICTION_ERROR,
      UPDATE_STATUS_SAME_STATUS_ERROR,
    ]);
  }

  return false;
};

export const errorBlackList: { (error: AxiosError): boolean }[] = [];
