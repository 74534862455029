import React, { useEffect, useState } from "react";
import { Button, Col, Input, message, Row } from "antd";
import { processError } from "../../../common/utils/error";
import { updateTaskNote } from "../../actions/creators/taskNote";
import { useTaskNoteDispatch } from "../../dispatchers";
import WorkflowTaskNoteService from "../../services/TaskNoteService";
import { TaskNoteAttributes } from "../../types/TaskNote";

type WorkflowTaskNotesFormProps = {
  workflowTaskId: number;
  note?: TaskNoteAttributes;
  onSave?: (note: TaskNoteAttributes) => void;
  onCancel?: () => void;
  disabled: boolean;
};

const WorkflowTaskNotesForm: React.FunctionComponent<WorkflowTaskNotesFormProps> = ({
  workflowTaskId,
  onSave,
  onCancel,
  note,
  disabled,
}: WorkflowTaskNotesFormProps) => {
  const [noteText, setNoteText] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const taskNoteDispatch = useTaskNoteDispatch();

  useEffect(() => {
    setNoteText(note?.note || "");
  }, [note]);

  const onInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    setNoteText(e.target.value);

  const handleSaveNote = async () => {
    if (!noteText.trim()) {
      message.error("Please fill out the note field");
      return;
    }

    let savedNote;

    setSubmitLoading(true);

    try {
      if (note) {
        const { data } = await WorkflowTaskNoteService.update(note.id, {
          note: noteText,
        });
        savedNote = data;
      } else {
        const { data } = await WorkflowTaskNoteService.create(workflowTaskId, {
          note: noteText,
        });
        savedNote = data;
      }
      setNoteText("");

      taskNoteDispatch(updateTaskNote(savedNote));

      if (onSave) onSave(savedNote);

      message.success("Successfully saved your note");
    } catch (error) {
      processError(error, (errorMessage) => {
        message.error(errorMessage);
      });
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <Row className="workflow-task-note-form mb-3">
      <Col span={24}>
        <Input.TextArea
          className="mb-4"
          value={noteText}
          onChange={onInputChange}
          maxLength={10000}
          showCount
          disabled={submitLoading || disabled}
          autoSize={{ minRows: 1, maxRows: 6 }}
          placeholder="Add note here"
        />
        <Button
          onClick={handleSaveNote}
          className="qf-btn-green mr-3"
          loading={submitLoading}
          disabled={disabled}
        >
          {note ? "Update" : "Add"} note
        </Button>
        {note && <Button onClick={onCancel}>Cancel</Button>}
      </Col>
    </Row>
  );
};

export default WorkflowTaskNotesForm;
