import React from "react";
import { PageRoute } from "@quest-finance/quest-fe-shared/dist/common/types/PageRoute";

const ApplicationDetailsPage = React.lazy(
  () => import("../pages/ApplicationDetails")
);

const routes: PageRoute[] = [
  {
    name: "Application Details",
    path: [
      "/application/applications/:applicationId",
      "/application/applications/:applicationId/:tabName",
    ],
    exact: true,
    component: ApplicationDetailsPage,
  },
];

export default routes;
