import "./CreditScore.scss";
import React, { useCallback, useEffect, useState } from "react";
import {
  SaveOutlined,
  LoadingOutlined,
  ReloadOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { LOADING_STATUS } from "@quest-finance/quest-fe-shared/dist/common/constants/loadingStatuses";
import { dateFormat } from "@quest-finance/quest-fe-shared/dist/common/utils/date";
import { ERROR_CODES } from "@quest-finance/quest-fe-shared/dist/error-handler";
import { Button, Col, Form, Input, message, Popconfirm, Row } from "antd";
import { AxiosError } from "axios";
import cs from "classnames";
import lodash from "lodash";
import { useSelector } from "react-redux";
import { DATE_LIST_FORMAT } from "../../../../common/contstants/app";
import { processError } from "../../../../common/utils/error";
import {
  getAssessmentExtras,
  getAssessmentExtrasSuccess,
} from "../../../actions/creators/assessmentExtras";
import {
  APPLICANT_SCORE_KEY,
  APPLICANT_EQUIFAX_TRADING_HISTORY_KEY,
  APPLICANT_EQUIFAX_INDEPTH_TRADING_HISTORY_KEY,
  FIRST_TIME_REQUEST_MESSAGE,
  SUBSEQUENT_REQUEST_MESSAGE,
} from "../../../constants/assessmentExtras";

import { useAssessmentExtrasDispatch } from "../../../dispatchers";
import {
  assessmentExtrasDataSelector,
  assessmentExtrasStatusSelector,
} from "../../../selectors/assessmentExtras";
import AssessmentExtrasService from "../../../services/AssessmentExtrasService";
import CreditService from "../../../services/CreditService";
import {
  GetAssessmentExtrasResponse,
  ScoreResponse,
} from "../../../types/AssessmentExtras";

type EquifaxButtonType = {
  equifaxData: ScoreResponse;
  assessmentId: string;
  applicantEntityId: number;
  children?: React.ReactChild;
  isIndepth?: boolean;
  className?: string;
};

const EquifaxButton: React.FC<EquifaxButtonType> = ({
  equifaxData,
  assessmentId,
  applicantEntityId,
  children,
  className,
  isIndepth = false,
}: EquifaxButtonType) => {
  const hasData = !lodash.isEmpty(equifaxData);
  const [equifaxRequestLoading, setEquifaxRequestLoading] = useState(false);
  const [refreshReportLoading, setRefreshReportLoading] = useState(false);
  const assessmentExtrasDispatch = useAssessmentExtrasDispatch();
  const showRedownload = isIndepth && hasData;

  const handleEquifaxError = (apiError: AxiosError) => {
    if (apiError.response?.data.errorCode === ERROR_CODES.TOO_MANY_REQUESTS) {
      message.error(
        "An ongoing request is still in progress. It will be completed short while."
      );
    } else {
      processError(apiError, (errorMessage) => {
        message.error(errorMessage);
      });
    }
  };

  const sendEquifaxRequest = async () => {
    setEquifaxRequestLoading(true);

    try {
      await CreditService.getEquifaxReport(
        assessmentId,
        [applicantEntityId],
        isIndepth
      );
      assessmentExtrasDispatch(
        getAssessmentExtras(assessmentId, {
          [APPLICANT_SCORE_KEY]: ["equifax"],
          [APPLICANT_EQUIFAX_TRADING_HISTORY_KEY]: ["score"],
          [APPLICANT_EQUIFAX_INDEPTH_TRADING_HISTORY_KEY]: ["score"],
        })
      );
      message.success(`Successfully retrieved score/documents for Applicant`);
    } catch (error) {
      handleEquifaxError(error as AxiosError);
    } finally {
      setEquifaxRequestLoading(false);
    }
  };

  const refreshIndepthReport = async () => {
    if (refreshReportLoading) return;

    setRefreshReportLoading(true);

    try {
      await CreditService.refreshIndepthReport(assessmentId);
      message.success(`Successfully refreshed In-depth history.`);
    } catch (error) {
      handleEquifaxError(error as AxiosError);
    } finally {
      setRefreshReportLoading(false);
    }
  };

  return (
    <>
      <Popconfirm
        getPopupContainer={() =>
          document.querySelector(".applicant-credit-score") as HTMLElement
        }
        title={
          <div className="confirm-message">
            <div>
              {hasData
                ? SUBSEQUENT_REQUEST_MESSAGE
                : FIRST_TIME_REQUEST_MESSAGE}
            </div>
            {equifaxData.updatedAt && (
              <strong>{`Last request was on ${dateFormat(
                new Date(equifaxData.updatedAt),
                DATE_LIST_FORMAT
              )}`}</strong>
            )}
          </div>
        }
        onConfirm={() => {
          sendEquifaxRequest();
        }}
        disabled={equifaxRequestLoading || refreshReportLoading}
      >
        <Button
          className={cs(
            className,
            "equifax-btn in-depth",
            hasData ? "qf-btn-gray-invert" : "qf-btn-green"
          )}
          loading={equifaxRequestLoading}
          disabled={refreshReportLoading}
        >
          {children}
        </Button>
      </Popconfirm>
      {showRedownload && (
        <Popconfirm
          getPopupContainer={() =>
            document.querySelector(".applicant-credit-score") as HTMLElement
          }
          title={
            <div className="confirm-message">
              Sure you want to refresh in-depth history report?
            </div>
          }
          onConfirm={() => {
            refreshIndepthReport();
          }}
          disabled={equifaxRequestLoading || refreshReportLoading}
        >
          <Button
            className="equifax-btn qf-btn-green"
            disabled={equifaxRequestLoading}
          >
            {refreshReportLoading ? <SyncOutlined spin /> : <ReloadOutlined />}
          </Button>
        </Popconfirm>
      )}
    </>
  );
};

type CreditSectionType = {
  assessmentId: string;
  applicantEntityId: number;
};

const CreditSection: React.FC<CreditSectionType> = ({
  assessmentId,
  applicantEntityId,
}: CreditSectionType) => {
  const [equifaxScore, setEquifaxScore] = useState("");
  const [isSavingEquifax, setSavingEquifax] = useState(false);
  const assessmentExtras = useSelector(assessmentExtrasDataSelector);
  const assessmentExtrasStatus = useSelector(assessmentExtrasStatusSelector);
  const assessmentExtrasDispatch = useAssessmentExtrasDispatch();
  const equifaxData = lodash.get(
    assessmentExtras,
    APPLICANT_EQUIFAX_TRADING_HISTORY_KEY,
    {}
  ) as ScoreResponse;
  const equifaxIndepthData = lodash.get(
    assessmentExtras,
    APPLICANT_EQUIFAX_INDEPTH_TRADING_HISTORY_KEY,
    {}
  ) as ScoreResponse;
  const setScore = useCallback((data: GetAssessmentExtrasResponse) => {
    const equifaxScore = lodash.get(
      data[APPLICANT_SCORE_KEY],
      "equifax",
      ""
    ) as string;

    setEquifaxScore(equifaxScore);
  }, []);

  useEffect(() => {
    if (assessmentExtras) {
      setScore(assessmentExtras);
    }
  }, [assessmentExtras, setScore]);

  const handleSaveEquifax = async () => {
    setSavingEquifax(true);

    try {
      const { data } = await AssessmentExtrasService.setData(assessmentId, [
        {
          type: APPLICANT_SCORE_KEY,
          data: {
            equifax: equifaxScore,
          },
        },
      ]);

      assessmentExtrasDispatch(getAssessmentExtrasSuccess(data));

      message.success(`Successfully saved applicant credit score`);
    } catch (error) {
      processError(error, (errorMessage) => {
        message.error(errorMessage);
      });
    } finally {
      setSavingEquifax(false);
    }
  };

  return (
    <div className="applicant-credit-score">
      <div className="section-header">Credit scores</div>
      <Form layout="vertical">
        <Row gutter={[16, 16]} align="bottom">
          <Col sm={12} xxl={6}>
            <Form.Item label="Equifax">
              <Input.Group compact>
                <Input
                  style={{ width: "calc(100% - 50px)" }}
                  value={equifaxScore}
                  disabled={assessmentExtrasStatus === LOADING_STATUS.LOADING}
                  onChange={(event) => {
                    setEquifaxScore(event.target.value);
                  }}
                  suffix={isSavingEquifax && <LoadingOutlined />}
                />
                <Button
                  disabled={
                    assessmentExtrasStatus === LOADING_STATUS.LOADING ||
                    isSavingEquifax
                  }
                  onClick={handleSaveEquifax}
                  className="score-button"
                >
                  <SaveOutlined />
                </Button>
              </Input.Group>
            </Form.Item>
          </Col>
          <Col sm={24} xxl={18} className="credit-buttons">
            <Form.Item>
              <EquifaxButton
                assessmentId={assessmentId}
                applicantEntityId={applicantEntityId}
                equifaxData={equifaxData}
              >
                Trading History
              </EquifaxButton>
              <EquifaxButton
                assessmentId={assessmentId}
                applicantEntityId={applicantEntityId}
                equifaxData={equifaxIndepthData}
                isIndepth={true}
              >
                In-depth History
              </EquifaxButton>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default CreditSection;
