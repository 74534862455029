import "./Contracts.scss";
import React, { useCallback, useEffect, useState } from "react";
import { FormOutlined } from "@ant-design/icons";
import { documentPurposes } from "@quest-finance/quest-fe-shared/dist/application";
import { dateFormat } from "@quest-finance/quest-fe-shared/dist/common/utils/date";
import {
  DocumentService,
  useDownloadDocument,
} from "@quest-finance/quest-fe-shared/dist/files";
import { Spin, Collapse, message, Empty } from "antd";
import lodash from "lodash";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { DATE_LIST_FORMAT } from "../../../common/contstants/app";
import { processError } from "../../../common/utils/error";
import { assessmentResponseSelector } from "../../selectors/assessmentDetail";
import { Document } from "../../types/Document";
import { QfCollapse } from ".././../../theme";

const { Panel } = Collapse;

const Contracts: React.FunctionComponent = () => {
  const assessment = useSelector(assessmentResponseSelector);
  const { downloadFile } = useDownloadDocument();
  const [documents, setDocuments] = useState<Document[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const getDocuments = useCallback(async () => {
    if (!assessment.externalId) return;
    setIsLoading(true);
    try {
      const result = await DocumentService.getDocumentList<Document>(
        `/application/applications/${assessment.externalId}/documents?` +
          `purposes=${documentPurposes.DOCUMENT_PURPOSES.GENERATED}` +
          `&purposes=${documentPurposes.DOCUMENT_PURPOSES.GENERATED_ESIGN}`
      );
      setDocuments(result?.data as Document[]);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      processError(error, (errorMessage) => {
        message.error(errorMessage);
      });
    }
  }, [assessment]);

  useEffect(() => {
    getDocuments();
  }, [getDocuments]);

  const handleFileDownload = (
    event: React.MouseEvent<HTMLAnchorElement>,
    documentId: string
  ) => {
    event.preventDefault();

    downloadFile(
      `/application/applications/${assessment.externalId}/documents/${documentId}/download`
    );
  };

  const renderFileNameColumn = (document: Document) => {
    return (
      <a
        href="/"
        onClick={(event: React.MouseEvent<HTMLAnchorElement>) =>
          handleFileDownload(event, document.id)
        }
      >
        {document.originalFilename}
      </a>
    );
  };

  return (
    <div className="mt-3 contracts-list">
      <QfCollapse
        defaultActiveKey={["1"]}
        className="p-x-0"
        expandIconPosition="right"
        ghost
      >
        <Panel
          header={<div className="section-header-1">Contracts</div>}
          key="1"
        >
          <div className="section-header-3">
            Generate Contract{" "}
            <Link
              to={`/application/applications/${assessment.externalId}/contracts`}
              className="edit-link"
            >
              <FormOutlined />
            </Link>
          </div>
          <Spin spinning={isLoading}>
            <table className="contracts-table">
              <tbody>
                {documents.length === 0 && (
                  <tr>
                    <td colSpan={3}>
                      <Empty description="No contracts found" />
                    </td>
                  </tr>
                )}
                {documents.map((document) => (
                  <tr key={document.id}>
                    <td>
                      {dateFormat(
                        new Date(document.createdAt),
                        DATE_LIST_FORMAT
                      )}
                    </td>
                    <td>
                      {lodash.isEmpty(document.createdBy)
                        ? "-"
                        : `${document.createdBy.firstName} ${document.createdBy.lastName}`}
                    </td>
                    <td>{renderFileNameColumn(document)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Spin>
        </Panel>
      </QfCollapse>
    </div>
  );
};

export default Contracts;
