import React, { useCallback, useEffect, useState } from "react";
import { SaveOutlined } from "@ant-design/icons";
import { getUserDataSelector } from "@quest-finance/quest-fe-shared/dist/auth";
import { UserData } from "@quest-finance/quest-fe-shared/dist/auth/types/UserData";
import { LabelValue } from "@quest-finance/quest-fe-shared/dist/common/types/LabelValue";
import { Button, Form, message, Spin } from "antd";
import { useSelector } from "react-redux";
import UserDropdown from "../../../common/user-dropdown";
import { processError } from "../../../common/utils/error";
import AssessmentService from "../../services/AssessmentService";
import {
  AssessmentAssigneeRequest,
  Assignee as AssigneeTypes,
  AssigneeRoles,
} from "../../types/Assignee";
import "./Assignees.scss";

type AssigneeProps = {
  assessmentId: string;
  assignees: AssigneeTypes[];
};
const Assignee: React.FunctionComponent<AssigneeProps> = ({
  assessmentId,
  assignees,
}: AssigneeProps) => {
  const [creditAnalyst, setCreditAnalyst] = useState<LabelValue<number>>();
  const [settlementOfficer, setSettlementOfficer] = useState<
    LabelValue<number>
  >();
  const [submitLoading, setSubmitLoading] = useState(false);
  const loggedInUser = useSelector(getUserDataSelector) as UserData;

  const onAssigneeSubmitCallback = useCallback(
    async (
      assessmentId: string,
      assigneeRequest: AssessmentAssigneeRequest[]
    ) => {
      setSubmitLoading(true);
      try {
        await AssessmentService.setAssignee(assessmentId, assigneeRequest);
        message.success("Assignee(s) successfully saved");
        setSubmitLoading(false);
      } catch (e) {
        setSubmitLoading(false);
        processError(e, (errorMessage) => {
          message.error(errorMessage);
        });
      }
    },
    []
  );

  useEffect(() => {
    const creditAnalyst = assignees.find(
      (assignee) => assignee.role === AssigneeRoles.CREDIT_ANALYST
    );
    const settlementOfficer = assignees.find(
      (assignee) => assignee.role === AssigneeRoles.SETTLEMENT_OFFICER
    );

    if (creditAnalyst) {
      setCreditAnalyst({
        value: creditAnalyst.user.id,
        label: `${creditAnalyst.user?.firstName} ${creditAnalyst.user?.lastName}`,
      });
    }

    if (settlementOfficer) {
      setSettlementOfficer({
        value: settlementOfficer.user?.id,
        label: `${settlementOfficer.user?.firstName} ${settlementOfficer.user?.lastName}`,
      });
    }
  }, [assignees]);

  const handleAssigneeSubmit = () => {
    const assigneeRequest: AssessmentAssigneeRequest[] = [];
    if (creditAnalyst) {
      assigneeRequest.push({
        userId: creditAnalyst.value,
        role: AssigneeRoles.CREDIT_ANALYST,
      });
    }
    if (settlementOfficer) {
      assigneeRequest.push({
        userId: settlementOfficer.value,
        role: AssigneeRoles.SETTLEMENT_OFFICER,
      });
    }
    onAssigneeSubmitCallback(assessmentId, assigneeRequest);
  };

  return (
    <div className="assignees">
      <Form.Item label="Credit Analyst" className="field">
        <UserDropdown
          value={creditAnalyst}
          onChange={(value) => setCreditAnalyst(value)}
          placeholder="Select credit analyst"
          clientIds={loggedInUser?.clientId ?? 1}
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
          disabled={submitLoading}
        />
      </Form.Item>
      <Form.Item label="Settlement Officer" className="field">
        <UserDropdown
          value={settlementOfficer}
          onChange={(value) => setSettlementOfficer(value)}
          placeholder="Select settlement officer"
          clientIds={loggedInUser?.clientId ?? 1}
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
          disabled={submitLoading}
        />
      </Form.Item>
      <Form.Item>
        <Button
          type="default"
          onClick={handleAssigneeSubmit}
          disabled={submitLoading}
        >
          {submitLoading ? <Spin /> : <SaveOutlined />}
        </Button>
      </Form.Item>
    </div>
  );
};

export default Assignee;
