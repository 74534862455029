import "./Settlement.scss";
import React from "react";
import { Collapse } from "antd";
import { useParams } from "react-router-dom";
import { SubmitApplicationBtn } from "../../../../lms";
import { QfCollapse } from "../../../../theme";
import { WORKFLOW_CODE } from "../../../../workflow/constants/workflow";
import InternalProcessing from "../../../components/internal-processing";
import Notes from "../../../components/notes";
import SupportingDocuments from "../../../components/supporting-documents/SupportingDocuments";
import WorkflowSection from "../../../components/workflow-section/WorkflowSection";
import { DocumentList } from "../../../types/Document";
import { Slug } from "../../../types/Navigation";

const { Panel } = Collapse;

const Settlement: React.FunctionComponent = () => {
  const urlParams = useParams<{
    assessmentId: string;
  }>();

  return (
    <>
      <InternalProcessing />
      <div className="content-section mt-3">
        <SubmitApplicationBtn assessmentId={urlParams.assessmentId} />
      </div>
      <QfCollapse defaultActiveKey={["1"]} expandIconPosition="right" ghost>
        <Panel
          className="notes-section-collapsible"
          header={<div className="section-header-1">Notes</div>}
          key="1"
        >
          <Notes />
        </Panel>
      </QfCollapse>
      <SupportingDocuments defaultAtiveTab={DocumentList.SETTLEMENT} />
      <WorkflowSection
        activeStep={Slug.SETTLEMENT}
        code={WORKFLOW_CODE.SETTLEMENT_WORKFLOW}
        targetId={urlParams.assessmentId}
      />
    </>
  );
};

export default Settlement;
