import "./WorkflowItem.scss";
import React, { useState } from "react";
import { InfoCircleFilled } from "@ant-design/icons";
import { applicationStatuses } from "@quest-finance/quest-fe-shared/dist/application";
import { Button, Popover, Spin } from "antd";
import cs from "classnames";
import _ from "lodash";
import { useSelector } from "react-redux";
import { assessmentStatusSelector } from "../../../assessment-v2/selectors/assessmentDetail";
import WordLimitter from "../../../common/components/WordLimitter/WordLimitter";
import {
  getWorkflowGroupTasks,
  getWorkflowSummary,
} from "../../actions/creators/workflow";
import { WORKFLOW_STATE, closedStatus } from "../../constants/workflow";
import { useWorkflowDispatch } from "../../dispatchers";
import { useTaskUpdate } from "../../hooks/useTaskUpdate";
import { WorkflowTask } from "../../types/Workflow";
import WorkflowModal from "../workflow-modal/WorkflowModal";
type TaskQuickActionProps = {
  workflowId: number;
  taskId: number;
  state: WORKFLOW_STATE;
  onUpdateTaskSuccess?: () => void;
};

const TaskQuickAction: React.FunctionComponent<TaskQuickActionProps> = ({
  workflowId,
  taskId,
  state,
  onUpdateTaskSuccess,
}: TaskQuickActionProps) => {
  const assessmentStatus = useSelector(
    assessmentStatusSelector
  ) as applicationStatuses.APPLICATION_STATUSES;
  const { isLoading, updateTaskState } = useTaskUpdate(
    workflowId,
    taskId,
    onUpdateTaskSuccess
  );
  const disabled = closedStatus.includes(assessmentStatus);
  const isPass = state === WORKFLOW_STATE.PASS;
  const isRefer = state === WORKFLOW_STATE.REFER;
  const isHold = state === WORKFLOW_STATE.HOLD;

  const taskUpdateHandler = (newState: WORKFLOW_STATE) => {
    if (state !== newState && !disabled) updateTaskState(newState);
  };

  return (
    <Spin spinning={isLoading}>
      <div className="state-btn-cont">
        <Button
          size="small"
          className={cs("state-btn", {
            "qf-btn-gray-invert hover-fill": !isPass,
            "qf-btn-green": isPass,
            inactive: disabled,
          })}
          disabled={disabled && !isPass}
          onClick={() => taskUpdateHandler(WORKFLOW_STATE.PASS)}
        >
          Pass
        </Button>
        <Button
          size="small"
          className={cs("state-btn", {
            "qf-btn-gray-invert hover-fill": !isHold,
            "qf-btn-orange": isHold,
            inactive: disabled,
          })}
          disabled={disabled && !isHold}
          onClick={() => taskUpdateHandler(WORKFLOW_STATE.HOLD)}
        >
          Hold
        </Button>
        <Button
          size="small"
          className={cs("state-btn", {
            "qf-btn-gray-invert hover-fill": !isRefer,
            "qf-btn-yellow": isRefer,
            inactive: disabled,
          })}
          disabled={disabled && !isRefer}
          onClick={() => taskUpdateHandler(WORKFLOW_STATE.REFER)}
        >
          Refer
        </Button>
      </div>
    </Spin>
  );
};

type WorkflowItemProps = {
  targetId: string;
  code: string;
  workflowId: number;
  groupCodes?: string | string[];
  filter?: WORKFLOW_STATE[];
  task: WorkflowTask;
  onUpdateTaskSuccess?: () => void;
};
const WorkflowItem: React.FunctionComponent<WorkflowItemProps> = ({
  targetId,
  code,
  workflowId,
  groupCodes,
  filter,
  task,
  onUpdateTaskSuccess,
}: WorkflowItemProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [expandNote, setExpandNote] = useState(false);
  const workflowDispatch = useWorkflowDispatch();
  const { taskTemplate, outcomeDescription } = task;
  let outcomeDetail = _.get(task, "outcomeParams.value");
  outcomeDetail =
    outcomeDetail && typeof outcomeDetail === "string"
      ? " - " + outcomeDetail
      : "";
  let info;

  const handleModalClose = () => {
    setIsModalVisible(false);
    workflowDispatch(getWorkflowSummary(targetId));
    workflowDispatch(getWorkflowGroupTasks(targetId, code, groupCodes, filter));
  };

  if (taskTemplate.description) {
    const content = (
      <div className="task-definition">{taskTemplate.description}</div>
    );

    info = (
      <Popover
        content={content}
        placement="right"
        getPopupContainer={(node) => node.parentElement as HTMLElement}
      >
        <InfoCircleFilled />
      </Popover>
    );
  }

  return (
    <>
      <tr
        className={cs("workflow-item", {
          declined: task.state === WORKFLOW_STATE.DECLINED,
        })}
      >
        <td>
          {task.state !== WORKFLOW_STATE.PENDING && (
            <TaskQuickAction
              workflowId={workflowId}
              state={task.state}
              taskId={task.id}
              onUpdateTaskSuccess={onUpdateTaskSuccess}
            />
          )}
        </td>
        <td className="workflow-item-description">
          <span className="trigger" onClick={() => setIsModalVisible(true)}>
            {taskTemplate.text}
          </span>{" "}
          {info}
        </td>
        <td>
          {outcomeDescription || ""}
          {outcomeDetail}
        </td>
        <td>
          <WordLimitter
            text={task.lastNote}
            maxLines={1}
            expanded={expandNote}
            setExpanded={() => setExpandNote(!expandNote)}
          />
        </td>
      </tr>
      {isModalVisible && (
        <WorkflowModal
          workflowId={workflowId}
          isVisible={isModalVisible}
          workflowTask={task}
          onCancel={() => handleModalClose()}
        />
      )}
    </>
  );
};

export default WorkflowItem;
