import React, { useCallback, useState, useEffect } from "react";
import { DeleteFilled } from "@ant-design/icons";
import { dateFormat } from "@quest-finance/quest-fe-shared/dist/common/utils/date";
import { useDownloadDocument } from "@quest-finance/quest-fe-shared/dist/files";
import { Empty, Popconfirm, Spin, message } from "antd";
import lodash from "lodash";
import { DATE_LIST_FORMAT } from "../../../common/contstants/app";
import { processError } from "../../../common/utils/error";
import WorkflowDocumentService from "../../services/WorkflowDocumentService";
import { WorkflowDocument } from "../../types/Document";
import "./DocumentList.scss";

type DocumentListProps = {
  workflowId?: number;
  workflowTaskId?: number;
};

const DocumentList: React.FunctionComponent<DocumentListProps> = ({
  workflowId,
  workflowTaskId,
}: DocumentListProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [documents, setDocuments] = useState<WorkflowDocument[]>([]);
  const { downloadFile } = useDownloadDocument();
  const handleFileDownload = (
    event: React.MouseEvent<HTMLAnchorElement>,
    { id, workflowTaskId }: WorkflowDocument
  ) => {
    event.preventDefault();
    downloadFile(`/workflow/task/${workflowTaskId}/documents/${id}/download`);
  };
  const handleFileDelete = async ({ id, workflowTaskId }: WorkflowDocument) => {
    setIsLoading(true);

    try {
      await WorkflowDocumentService.deleteDocument(workflowTaskId, id);
      message.success("Worfklow document has been deleted.");
      getDocuments();
    } catch (e) {
      processError(e, (errorMessage) => {
        message.error(errorMessage);
      });
      setIsLoading(false);
    }
  };

  const renderFileNameColumn = (document: WorkflowDocument) => {
    return (
      <a
        href="/"
        onClick={(event: React.MouseEvent<HTMLAnchorElement>) =>
          handleFileDownload(event, document)
        }
      >
        {document.originalFilename}
      </a>
    );
  };

  const renderDeleteButton = (document: WorkflowDocument) => {
    return (
      <Popconfirm
        title="Sure to delete?"
        onConfirm={() => handleFileDelete(document)}
        placement="left"
      >
        <DeleteFilled className="delete-button" />
      </Popconfirm>
    );
  };

  const getDocuments = useCallback(async () => {
    setIsLoading(true);
    try {
      let docs: WorkflowDocument[] = [];

      if (workflowTaskId) {
        const result = await WorkflowDocumentService.getList(workflowTaskId);
        docs = result.data;
      } else if (workflowId !== undefined) {
        const result = await WorkflowDocumentService.getListForWorkflow(
          workflowId
        );
        docs = result.data;
      }

      setDocuments(docs);
    } catch (e) {
      processError(e, (errorMessage) => {
        message.error(errorMessage);
      });
    } finally {
      setIsLoading(false);
    }
  }, [setDocuments, workflowId, workflowTaskId]);

  useEffect(() => {
    getDocuments();
  }, [getDocuments, workflowId]);

  return (
    <Spin spinning={isLoading}>
      <div className="workflow-documents">
        {!isLoading && documents.length === 0 && (
          <Empty description="No documents found" />
        )}
        <table>
          <tbody>
            {documents.map((document) => (
              <tr key={document.id}>
                <td>
                  {dateFormat(new Date(document.createdAt), DATE_LIST_FORMAT)}
                </td>
                <td>
                  {lodash.isEmpty(document.creator)
                    ? "-"
                    : `${document.creator.firstName} ${document.creator.lastName}`}
                </td>
                <td>
                  {renderFileNameColumn(document)}
                  {renderDeleteButton(document)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Spin>
  );
};

export default DocumentList;
