import "./WorkflowNotes.scss";
import React, { useState, useCallback, useEffect, useMemo } from "react";
import { DATATABLE_MAX_ROWS } from "@quest-finance/quest-fe-shared/dist/common/constants/datatable";
import { getOffset } from "@quest-finance/quest-fe-shared/dist/common/utils/dataTable";
import { dateFormat } from "@quest-finance/quest-fe-shared/dist/common/utils/date";
import { Button, Table, Tooltip, message, Select, Row, Col, Form } from "antd";
import { SorterResult } from "antd/es/table/interface";
import type { ColumnsType, TableProps } from "antd/lib/table";
import { SortOrder } from "antd/lib/table/interface";
import { useSelector } from "react-redux";
import { workflowSelectors } from "../..";
import WordLimitter from "../../../common/components/WordLimitter/WordLimitter";
import {
  SHORT_DATE_FORMAT,
  DATE_LIST_FORMAT,
} from "../../../common/contstants/app";
import { processError } from "../../../common/utils/error";
import { WORKFLOW_SECTIONS_LABEL } from "../../constants/sections";
import { WORKFLOW_CODE } from "../../constants/workflow";
import WorkflowService from "../../services/WorkflowService";
import { TaskNoteAttributes } from "../../types/TaskNote";
import { ListNoteRequest } from "../../types/Workflow";

const WorkflowNotes: React.FC = () => {
  const workflows = useSelector(workflowSelectors.workflowDetailsSelector);
  const selectedWorkflow = useMemo(() => {
    return workflows.find(
      (workflow) => workflow.code === WORKFLOW_CODE.CREDIT_WORKFLOW
    );
  }, [workflows]);
  const [notes, setNotes] = useState<TaskNoteAttributes[]>([]);
  const [totalNotes, setTotalNotes] = useState(0);
  const [expandedNotes, setExpandedNotes] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAllExpanded, setIsAllExpanded] = useState(false);
  const [workflowGroupId, setWorkflowGroupId] = useState<number>();
  const [groupOptions, setGroupOptions] = useState<Record<string, number>>();
  const [sortOrder, setSortOrder] = useState<SortOrder>("descend");
  const [page, setPage] = useState<number>(1);

  const getNotes = useCallback(
    async (queryParams: ListNoteRequest) => {
      setIsLoading(true);
      try {
        const { data, count } = await WorkflowService.getNotes(
          workflows[0].id,
          {
            ...queryParams,
            limit: DATATABLE_MAX_ROWS,
          }
        );
        setNotes(data);
        setTotalNotes(count as number);
      } catch (e) {
        processError(e, (errorMessage) => {
          message.error(errorMessage);
        });
      } finally {
        setIsLoading(false);
      }
    },
    [workflows]
  );

  useEffect(() => {
    if (selectedWorkflow)
      getNotes({
        offset: 0,
        workflowGroupId,
      });
  }, [getNotes, selectedWorkflow, workflowGroupId]);

  useEffect(() => {
    if (selectedWorkflow && selectedWorkflow.groups) {
      const workflowGroups: Record<string, number> = {};
      for (const group in selectedWorkflow.groups) {
        const workflowGroup = selectedWorkflow.groups[group];
        workflowGroups[group] = workflowGroup.id;
      }
      setGroupOptions(workflowGroups);
    }
  }, [selectedWorkflow]);

  const toggleNoteItem = (id: number, expanded: boolean) => {
    setExpandedNotes((previousState) => {
      if (expanded) {
        return [...previousState, id];
      }

      return previousState.filter((noteId) => noteId !== id);
    });
  };

  const toggleExpandedState = (expand: boolean) => {
    setIsAllExpanded(expand);
    setExpandedNotes(() => (expand ? notes.map(({ id }) => id) : []));
  };

  const onTableChangeHandler: TableProps<TaskNoteAttributes>["onChange"] = (
    pagination,
    _,
    sorter
  ) => {
    const { columnKey, order } = (sorter as unknown) as SorterResult<
      TaskNoteAttributes
    >;
    const sortPrefix = order && order === "descend" ? "-" : "";
    setSortOrder(order ?? null);

    const pageNumber = sortOrder !== order ? 1 : pagination.current;

    setPage(pageNumber ?? 1);
    getNotes({
      workflowGroupId,
      offset: pageNumber ? getOffset(pageNumber, DATATABLE_MAX_ROWS) : 0,
      order: columnKey ? [sortPrefix, columnKey].join("") : undefined,
    });
    toggleExpandedState(false);
  };

  const columns: ColumnsType<TaskNoteAttributes> = [
    {
      title: "Date",
      className: "date",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      sortOrder,
      width: "7%",
      render(_, { createdAt }) {
        const date = new Date(createdAt);
        return (
          <Tooltip title={dateFormat(date, DATE_LIST_FORMAT)}>
            {dateFormat(date, SHORT_DATE_FORMAT)}
          </Tooltip>
        );
      },
    },
    {
      title: "Group",
      className: "group",
      dataIndex: "groupTitle",
      width: "10%",
    },
    {
      title: "Task",
      className: "task",
      dataIndex: "taskText",
      width: "20%",
    },
    {
      title: "Name",
      className: "name",
      render(_, { creator: { firstName, lastName } }) {
        return (
          <>
            {firstName} {lastName}
          </>
        );
      },
      width: "13%",
    },
    {
      title: "Note",
      className: "note",
      render(_, { id, note }) {
        return (
          <WordLimitter
            text={note}
            maxLines={2}
            expanded={expandedNotes.includes(id)}
            setExpanded={(expanded: boolean) => toggleNoteItem(id, expanded)}
          />
        );
      },
    },
  ];

  const handleSection = (value: number) => {
    setWorkflowGroupId(value);
  };

  return (
    <div className="workflow-notes">
      {groupOptions && (
        <Row>
          <Col lg={6}>
            <Form>
              <Form.Item>
                <Select
                  onChange={handleSection}
                  value={workflowGroupId}
                  placeholder="Select a group to filter"
                  allowClear={true}
                >
                  {Object.keys(groupOptions).map((groupName) => (
                    <Select.Option
                      key={groupName}
                      value={groupOptions[groupName]}
                    >
                      {WORKFLOW_SECTIONS_LABEL[groupName]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      )}
      <div>
        <Button
          className="qf-btn-green mb-3"
          onClick={() => toggleExpandedState(!isAllExpanded)}
        >
          {isAllExpanded ? "Collapse all" : "Expand all"}
        </Button>
      </div>
      <Table
        loading={isLoading}
        columns={columns}
        dataSource={notes}
        pagination={{
          simple: true,
          total: totalNotes,
          current: page,
        }}
        onChange={onTableChangeHandler}
        rowKey="id"
        size="small"
      />
    </div>
  );
};

export default WorkflowNotes;
