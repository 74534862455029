import "./ApplicationOverview.scss";
import React from "react";
import {
  ApplicationResponse,
  getApplicationSelector,
  getDetailsLoadingSelector,
} from "@quest-finance/quest-fe-shared/dist/application";
import { LOADING_STATUS } from "@quest-finance/quest-fe-shared/dist/common/constants/loadingStatuses";
import { Col, Row, Form, Spin } from "antd";
import { useSelector } from "react-redux";
import { updateAssessmentDetail } from "../../actions/creators/assessmentDetail";
import { useAssessmentDetailsDispatch } from "../../dispatchers";
import {
  assessmentResponseStatusSelector,
  assessmentResponseSelector,
} from "../../selectors/assessmentDetail";
import { AssessmentDetail } from "../../types/AssessmentDetail";
import AssessmentStatus from "../assessment-status/AssessmentStatus";
import Assignee from "../assignees/Assignees";
import IntroducerDetails from "../introducer-details/IntroducerDetails";
import LinkedAccounts from "./LinkedAccounts/LinkedAccounts";

type ApplicationOverviewProps = {
  onCheckExistingCustomer?: (application: ApplicationResponse) => void;
};

const ApplicationOverview: React.FunctionComponent<ApplicationOverviewProps> = ({
  onCheckExistingCustomer,
}: ApplicationOverviewProps) => {
  const assessmentLoadingStatus = useSelector(assessmentResponseStatusSelector);
  const isRetrievingApplication = useSelector(getDetailsLoadingSelector);
  const assessment = useSelector(assessmentResponseSelector);
  const { user }: ApplicationResponse = useSelector(getApplicationSelector);
  const assessmentDetailDispatch = useAssessmentDetailsDispatch();

  const onUpdateStatusSuccess = ({
    status,
    statusReason,
    reason,
  }: Partial<AssessmentDetail>) => {
    assessmentDetailDispatch(
      updateAssessmentDetail({ status, statusReason, reason })
    );
  };

  return (
    <div className="application-overview">
      <Spin
        spinning={
          assessmentLoadingStatus === LOADING_STATUS.LOADING ||
          isRetrievingApplication === true
        }
      >
        <Form layout="vertical">
          <Row gutter={[32, 16]}>
            <Col span={12}>
              <AssessmentStatus
                assessment={assessment}
                onSuccess={onUpdateStatusSuccess}
              />
            </Col>
            <Col span={12}>
              <Assignee
                assessmentId={assessment.id}
                assignees={assessment.assignees}
              />
            </Col>
          </Row>
          <Row gutter={[32, 16]}>
            <Col span={12} className="existing-customer">
              <LinkedAccounts />
            </Col>
            <Col span={12}>
              <div className="introducer">
                <Row>
                  <Col span={24}>
                    <label className="f-bold f-quest-navy">Introducer</label>
                  </Col>
                  <Col span={12}>
                    {!!user && (
                      <>
                        <div className="name">{user.client}</div>
                        <div>
                          {`${user.firstName} ${user.lastName} `}
                          {user.mobile ? `(${user.mobile})` : null}
                        </div>
                        <div className="user-email">
                          <a href={`mailto:${user.email}`}>{user.email}</a>
                        </div>
                      </>
                    )}
                  </Col>
                  <Col span={12}>
                    <IntroducerDetails />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <div className="box-cont">
            <div className="box"></div>
            <div className="box"></div>
          </div>
        </Form>
      </Spin>
    </div>
  );
};

export default ApplicationOverview;
