import { ListResponse } from "@quest-finance/quest-fe-shared/dist/common/types/ListResponse";
import { SingleResponse } from "@quest-finance/quest-fe-shared/dist/common/types/SingleResponse";
import { put, call, ForkEffect, takeLatest } from "redux-saga/effects";
import * as actionCreators from "../actions/creators/workflow";
import * as actionTypes from "../actions/types/workflow";
import WorkflowService from "../services/WorkflowService";
import {
  WorkflowGroupResponse,
  WorkflowResponse,
  WorkflowSummaryDetails,
} from "../types/Workflow";
import { extractWorkflowGroup } from "../utils/workflow";

function* getWorkflowDetailsWorker({
  targetId,
  state,
}: actionTypes.GetWorkflowDetails) {
  try {
    const result: ListResponse<WorkflowResponse> = yield call(
      WorkflowService.getDetails,
      targetId,
      state
    );
    yield put(actionCreators.getWorkflowDetailsSuccess(result.data));
  } catch (e) {
    yield put(actionCreators.getWorkflowDetailsFailed(e));
  }
}

function* getWorkflowSummaryWorker({
  targetId,
}: actionTypes.GetWorkflowSummary) {
  try {
    const result: SingleResponse<WorkflowSummaryDetails> = yield call(
      WorkflowService.getWorkflowGroupSummary,
      targetId
    );
    yield put(actionCreators.getWorkflowSummarySuccess(result.data));
  } catch (e) {
    yield put(actionCreators.getWorkflowSummaryFailed(e));
  }
}

function* getWorkflowGroupTasksWorker({
  targetId,
  code,
  groupCodes,
  state,
}: actionTypes.GetWorkflowGroupTasks) {
  try {
    const result: SingleResponse<WorkflowGroupResponse[]> = yield call(
      WorkflowService.getWorkflowGroupTasks,
      targetId,
      code,
      groupCodes,
      state ? state.join(",") : undefined
    );

    const { tasks, groupIds, count } = extractWorkflowGroup(result.data);

    yield put(
      actionCreators.getWorkflowGroupTasksSuccess(groupIds, tasks, count)
    );
  } catch (e) {
    yield put(actionCreators.getWorkflowGroupTasksFailed(e));
  }
}

function* watchAdminData(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(actionTypes.GET_WORKFLOW_DETAILS, getWorkflowDetailsWorker);
  yield takeLatest(actionTypes.GET_WORKFLOW_SUMMARY, getWorkflowSummaryWorker);
  yield takeLatest(
    actionTypes.GET_WORKFLOW_GROUP_TASKS,
    getWorkflowGroupTasksWorker
  );
}

export default watchAdminData;
