import React from "react";
import { useParams } from "react-router-dom";
import { WORKFLOW_CODE } from "../../../../workflow/constants/workflow";
import QuoteData from "../../../components/quote-data/QuoteData";
import WorkflowSection from "../../../components/workflow-section/WorkflowSection";
import { Slug } from "../../../types/Navigation";

const Quote: React.FunctionComponent = () => {
  const urlParams = useParams<{
    assessmentId: string;
  }>();
  return (
    <>
      <QuoteData />
      <WorkflowSection
        activeStep={Slug.QUOTE}
        code={WORKFLOW_CODE.CREDIT_WORKFLOW}
        targetId={urlParams.assessmentId}
      />
    </>
  );
};

export default Quote;
