import { routes as authRoutes } from "@quest-finance/quest-fe-shared/dist/auth";
import { PageRoute } from "@quest-finance/quest-fe-shared/dist/common/types/PageRoute";
import { routes as userRoutes } from "@quest-finance/quest-fe-shared/dist/user";
import { routes as applicationRoutes } from "../application";
import { routes as assessmentRoutes } from "../assessment";
import { routes as funderRoutes } from "../funder";
import { routes as iamRoutes } from "../iam";

const privateRoutes: PageRoute[] = [
  ...assessmentRoutes,
  ...funderRoutes,
  ...iamRoutes,
  ...userRoutes,
  ...applicationRoutes,
];
const publicRoutes: PageRoute[] = [...authRoutes];

export { privateRoutes, publicRoutes };
